import React, { useState } from "react";
import "./ContactForm.css";
import SendModal from "./SendModal";
import { API } from "../CONFIG";
import Axios from "axios";
import { useForm } from "react-hook-form";

const ContactForm = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const onSubmit = (data) => {
    Axios.post(`${API}/general/comments`, {
      name: data.firstName,
      mail: data.email,
      phone: data.phone,
      text: data.description,
    });
    reset();
    setShowModal(true);
    setModalMessage(
      `Gracias! en breve nos pondremos en contacto a ${data.email}`
    );
  };

  return (
    <div className="contact__form">
      <form onSubmit={handleSubmit(onSubmit)} className="contact__contact">
        <div className="contact__input-group">
          <label htmlFor="firstName">Nombre Completo</label>
          <input
            type="name"
            placeholder="Ingrese su nombre"
            {...register("firstName", {
              validate: {
                name: (value) => value !== "",
              },
              pattern:
                /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u,
            })}
          />
          {errors.firstName && errors.firstName.type === "pattern" && (
            <p>Ingrese un nombre valido</p>
          )}
          {errors.firstName && errors.firstName.type === "name" && (
            <p>Ingrese un nombre</p>
          )}
        </div>

        <div className="contact__input-group">
          <label htmlFor="phone">Teléfono</label>
          <input
            type="number"
            {...register("phone", {
              validate: {
                phonemax: (value) => value.length === 10,
              },
              pattern: /[0-9]/,
            })}
          />
          {errors.phone && errors.phone.type === "pattern" && (
            <p>El teléfono solo puede contener numeros</p>
          )}
          {errors.phone && errors.phone.type === "phonemax" && (
            <p>El teléfono debe contener 10 numeros</p>
          )}
        </div>

        <div className="contact__input-group">
          <label htmlFor="email">Correo</label>
          <input
            placeholder="name@example.com"
            type="email"
            {...register("email")}
          />
        </div>

        <div className="contact__input-group">
          <label htmlFor="company">Empresa Representante</label>
          <input
            {...register("company", {
              maxLength: 20,
              validate: {
                companynull: (value) => value !== "",
              },
            })}
          />
          {errors.company?.type === "maxLength" && (
            <p>No puedes exceder de 20 caracteres</p>
          )}
          {errors.company && errors.company.type === "companynull" && (
            <p>Este campo no puede estar vacio</p>
          )}
        </div>

        <div className="contact__input-group">
          <label htmlFor="service">¿Qué servicio está buscando?</label>
          <select {...register("service")} name="service">
            <option value="equipo">Equipo</option>
            <option value="controles">Controles</option>
            <option value="instalacion completa">Instalación completa </option>
            <option value="mantenimiento">Mantenimiento</option>
            <option value="soporte tecnico">Soporte técnico</option>
          </select>
        </div>

        <div className="contact__input-group">
          <label htmlFor="description">Breve descripción de su necesidad</label>
          <textarea
            {...register("description", {
              maxLength: 80,
              required: true,
            })}
            name="description"
          ></textarea>
          {errors.description?.type === "maxLength" && (
            <p>Maximo de 80 caracteres</p>
          )}
          {errors.description?.type === "required" && (
            <p>Este campo no puede estar vacio</p>
          )}
        </div>
        <div className="contact__input-group">
          <input className="contact__submit" type="submit" />
        </div>
      </form>
      {showModal && (
        <SendModal msg={modalMessage} onClick={() => setShowModal(false)} />
      )}
    </div>
  );
};

export default ContactForm;

import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

function Viewers() {
  return (
    <Container>
      <Wrap>
        <Link to="/products/6/KU">
          <img src="/img/MINI_1.jpg" alt="" />
          <video
            src="./videos/KUMA_MINI.mp4"
            type="video/mp4"
            autoPlay={true}
            loop={true}
            playsInline={true}
          />
        </Link>
      </Wrap>
      <Wrap>
        <Link to="/products/1/VU">
          <img src="/img/MINI_2.jpg" alt="" />
          <video
            src="./videos/VULCAN_MINI.mp4"
            type="video/mp4"
            autoPlay={true}
            loop={true}
            playsInline={true}
          />
        </Link>
      </Wrap>
      <Wrap>
        <Link to="/products/1/TE">
          <img src="/img/MINI_3.jpg" alt="" />
          <video
            src="/videos/TEMIS_MINI.mp4"
            type="video/mp4"
            autoPlay={true}
            loop={true}
            playsInline={true}
          />
        </Link>
      </Wrap>
    </Container>
  );
}

export default Viewers;

const Container = styled.div`
  display: grid;
  margin-top: 30px;
  padding: 30px 0px 26px;
  grid-gap: 25px;
  gap: 25px;
  grid-template-columns: repeat(3, minmax(0, 1fr));

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

const Wrap = styled.div`
  padding-top: 56.25%;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 50%) 0px 26px 30px -10px,
    rgb(0 0 0 / 50%) 0px 16px 10px -10px;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0%;
  border: 3px solid rgba(249, 249, 249, 0.1);
  transition: all 0.5s;

  :hover {
    transform: scale(1.15);
    z-index: 2;
  }

  img {
    inset: 0px;
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
    opacity: 1;
    position: absolute;
  }

  video {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    opacity: 0;
    z-index: 0;
    transition: all 0.5s;

    :hover {
      opacity: 1;
      transform: scale(1.2);
      z-index: 2;
    }
  }

  @media (max-width: 768px) {
    img {
      inset: 0px;
      display: block;
      height: 100%;
      width: 100%;
      object-fit: cover;
      opacity: 1;
      position: absolute;
    }

    video {
      position: fixed;
      width: 125%;
      height: 125%;
      position: absolute;
      top: 0px;
      opacity: 0;
      z-index: 0;
      transition: all 0.5s;

      :hover {
        top: -30px;
        left: -40px;
        opacity: 1;
        transform: scale(1);
        z-index: 2;
      }
    }
  }
`;

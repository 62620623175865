class CartItem {
  constructor(
    model,
    stock,
    img,
    sucursal,
    price,
    quantity,
    sum,
    description,
    sucursalName
  ) {
    this.model = model;
    this.stock = stock;
    this.img = img;
    this.sucursal = sucursal;
    this.price = price;
    this.quantity = quantity;
    this.sum = sum;
    this.description = description;
    this.sucursalName = sucursalName;
  }
}
export default CartItem;

import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

function Recommends(props) {
  return (
    <Container>
      <h4>{props.title}</h4>
      <Content>
        <Wrap>
          <Link to="/products/3/IZ">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/app-isia.appspot.com/o/Categorias%2FSeccionales%2FIzar.png?alt=media&token=c14ca605-57f3-44f0-8d15-9decc51c7596"
              alt=""
            />
          </Link>
          <span>Motores Izar</span>
        </Wrap>
        <Wrap>
          <Link to="/products/5/AN">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/app-isia.appspot.com/o/Categorias%2FBarreras%2Fnet-producto-1.png?alt=media&token=5e66eaa0-edef-4dd6-872d-cd8b9c323f8c"
              alt=""
            />
          </Link>
          <span>Barreras Net</span>
        </Wrap>
        <Wrap>
          <Link to="/products/4/ER">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/app-isia.appspot.com/o/Categorias%2FEnrollables%2Fergos-producto-1.png?alt=media&token=36cc4e4a-38da-4ea9-b6eb-f825df184c45"
              alt=""
            />
          </Link>
          <span>Motor Ergos</span>
        </Wrap>
        <Wrap>
          <Link to="/products/6/LR">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/app-isia.appspot.com/o/Categorias%2FRadios%2Fmando-lira-instalacion-1024x519.png?alt=media&token=9870573d-1e24-4320-adec-12ee482c769b"
              alt=""
            />
          </Link>
          <span>Emisor Lira</span>
        </Wrap>
        <Wrap>
          <Link to="/products/2/RI">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/app-isia.appspot.com/o/Categorias%2FCorredizas%2FRino.PNG?alt=media&token=d7497591-aeb9-4b4b-9865-cb87e9deb4ae"
              alt=""
            />
          </Link>
          <span>Motores Rino</span>
        </Wrap>
      </Content>
    </Container>
  );
}

export default Recommends;

const Container = styled.div`
  padding: 0 0 3%;
  h4 {
    font-size: 27px;
  }
`;

const Content = styled.div`
  display: grid;
  grid-gap: 20px;
  gap: 20px;
  grid-template-columns: repeat(5, minmax(0, 1fr));

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

const Wrap = styled.div`
  padding-top: 56.25%;
  position: relative;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 20%) 0px 26px 30px -10px,
    rgb(0 0 0 / 20%) 0px 16px 10px -10px;
  cursor: pointer;
  overflow: hidden;
  transition: all 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  border: 2px solid rgba(249, 249, 249, 0.1);
  padding-bottom: 20px;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    inset: 0px;
    position: absolute;
    object-fit: contain;
    height: 85%;
    width: 100%;
  }

  span {
    font-size: 18px;
    color: #193073;
    font-weight: 600;
    position: absolute;
    margin-top: 10px;
  }

  &:hover {
    transform: scale(1.15);
    border: 1px solid #193073;
    z-index: 2;
  }
`;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { useSelector } from 'react-redux';

export default function Precios({price}) {
    const Pcambio= useSelector(state=> state.cart.Pcambio);
    const cambio= useSelector(state=> state.cart.cambio);
    const iva= useSelector(state=> state.cart.iva);
    const [precio, setPrecio] = useState(0.0);
    
    useEffect(()=>{
        if(!!price){
            setPrecio(price*Pcambio);
        }else{
            setPrecio(0.0)
        }
    },[price,Pcambio]);
  return (
    <Contain>
        <Price>
            <MonetizationOnIcon />
            {cambio
                ?
                iva ? <span> {(price * 1.16).toFixed(2)} {`USD`}</span> : <span> {price} {`USD`}</span>
                :
                iva ? <span>{`${(precio * 1.16).toFixed(2)} MXN`}</span> : <span>{`${ precio.toFixed(2)} MXN`}</span>
            }
        </Price>
    </Contain>
  )
}
const Price = styled.div`
    display: flex;
    align-items: center;
    margin-top: 25px;
    width: 60%;
    height:40px;
    justify-content: space-evenly;
    border-radius: 8px;
    background-color: #193073;
    color: white;
    a{
        margin: 0;
        color: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        outline: none;
        text-decoration: none;
    }
    .MuiSvgIcon-root{
        width: 30px;
        margin-top: -10px;
        margin-bottom: -10px;
        color: white;
    }
`;

const Contain = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
`;
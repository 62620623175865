import { types } from "../types/types";
import { API, TOKEN } from "../../CONFIG";
import axios from "axios";

export const addToCart = (
  code,
  stock,
  model,
  img,
  sucursal,
  quantity,
  price,
  description,
  sucursalName
) => ({
  type: types.addToCart,
  payload: {
    item: {
      code,
      stock,
      model,
      img,
      sucursal,
      quantity,
      price,
      description,
      sucursalName,
    },
  },
});
export const addToCartPet = (
  code,
  stock,
  model,
  img,
  sucursal,
  quantity,
  price,
  description
) => {
  return (dispatch) => {
    axios
      .get(`${API}/sucursal/info/name/${sucursal}`)
      .then((res) => {
        dispatch(
          addToCart(
            code,
            stock,
            model,
            img,
            sucursal,
            quantity,
            price,
            description,
            res.data
          )
        );
      })
      .catch((e) => {});
  };
};

export const emptyCart = () => ({
  type: types.emptyCart,
});
export const removeToCart = (code) => ({
  type: types.removeToCart,
  payload: {
    code: code,
  },
});

export const updateItem = (code, quantity) => ({
  type: types.updateItem,
  payload: {
    code: code,
    quantity: quantity,
  },
});
export const updateStock = (code, stock) => ({
  type: types.updateStock,
  payload: {
    code: code,
    stock: stock,
  },
});
//Cambiar el precio de los productos
export const setCambio = (cambio) => ({
  type: types.cambio,
  payload: cambio,
});

export const setIva = (iva) => ({
  type: types.iva,
  payload: iva,
});
export const setPrice = (cambio) => ({
  type: types.precios,
  payload: {
    Pcambio: +cambio,
  },
});
export const getPrices = () => {
  return (dispatch) => {
    const date = new Date();
    const day = date.getDate();
    let actualMonth = date.getMonth() + 1;
    const year = date.getFullYear();

    if (actualMonth < 10) {
      actualMonth = "0" + actualMonth;
    }
    const today = day + "-" + actualMonth + "-" + year;
    //para sacar el cambio de moneda segun los indicadores de la segob
    fetch(`https://sidofqa.segob.gob.mx/dof/sidof/indicadores/${today}`)
      .then((responsed) => responsed.json())
      .then((responsediario) => {
        const cambio1 = responsediario.ListaIndicadores[0].valor;
        //Para sacar el cambio de moneda segun banxico.
        fetch(
          `https://www.banxico.org.mx/SieAPIRest/service/v1/series/SF43718/datos/oportuno?token=${TOKEN}`
        )
          .then((response) => response.json())
          .then((responsedecode) => {
            const cambio2 = responsedecode.bmx.series[0].datos[0].dato;
            if (parseFloat(cambio1) > parseFloat(cambio2)) {
              dispatch(setPrice(cambio1));
            } else {
              dispatch(setPrice(cambio2));
            }
          })
          .catch(() => {
            console.log("Ha ocurrido un error");
            //dispatch(setPrice(cambio1));
          });
      })
      .catch(() => {});
  };
};

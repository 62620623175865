import React from "react";
import "./SendModal.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
const SendModal = (props) => {
  return (
    <div className="sendModal fadeDown" onClick={props.onClick}>
      <div className="modal__container">
        <CheckCircleIcon fontSize="large" />
        <h2>{props.msg}</h2>
        <button onClick={props.onClick}>Ok</button>
      </div>
    </div>
  );
};

export default SendModal;

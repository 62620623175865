import React, { useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import Axios from "axios";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import {
  Alert,
  Backdrop,
  Button,
  CircularProgress,
  TextField,
} from "@mui/material";

import { API } from "../../CONFIG";
import { loginUser } from "../../store/actions/auth";
import "./LoginUser.css";

const LoginUser = () => {
  const dispatch = useDispatch();
  const { handleSubmit, control } = useForm({ mode: "all" });
  const [er, setEr] = useState("");
  const history = useHistory();
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const onSubmit = (data, e) => {
    e.preventDefault();
    setEr("");
    setOpenBackdrop(true);
    Axios.post(`${API}/auth/user/login`, {
      username: data.username,
      password: data.password,
    })
      .then((res) => {
        if (res.data.auth) {
          const { id, type, username, suc_clave } = res.data.user;
          window.localStorage.setItem("TOKENISIA", res.data.token);
          setOpenBackdrop(false);
          dispatch(loginUser(id, type, username, suc_clave));
          history.replace("/dashboard");
        } else {
          setOpenBackdrop(false);
          setEr(res.data.message);
        }
      })
      .catch((e) => {
        setOpenBackdrop(false);
        setEr("Ha ocurrido un error Favor de intentar de nuevo");
      });
  };
  return (
    <div className="admin__container">
      <div className="admin__form-container">
        <h1>Inicio de sesión</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormContainer>
            <FormItems>
              <Controller
                control={control}
                name="username"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    size="small"
                    type="user"
                    label="Usuario"
                    variant="outlined"
                    error={!!error}
                    helperText={error?.type === "text" && "Usuario requerido"}
                    inputProps={{ maxLength: 20 }}
                  />
                )}
                rules={{
                  validate: {
                    text: (value) => value !== "",
                  },
                }}
              />
            </FormItems>
            <FormItems>
              <Controller
                control={control}
                name="password"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    size="small"
                    type="password"
                    label="Contraseña"
                    variant="outlined"
                    error={!!error}
                    helperText={
                      error?.type === "password" && "Contraseña requerida"
                    }
                    inputProps={{ maxLength: 30 }}
                  />
                )}
                rules={{ validate: { password: (value) => value !== "" } }}
              />
            </FormItems>
          </FormContainer>
          {er && (
            <Alert icon={false} variant="filled" severity="error">
              {er}
            </Alert>
          )}
          <FormItems style={{ alignItems: "center" }}>
            <Button variant="outlined" type="submit">
              Iniciar Sesión
            </Button>
          </FormItems>
        </form>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};
const FormContainer = styled.div`
  justify-content: center;
`;
const FormItems = styled.div`
  width: 100%;
  padding: 10px;
  font-weight: 500;
  margin-bottom: 20px;
`;
export default LoginUser;

import React from 'react'
import './Maps.css'

const Maps = (props) => {
  return (
    <div className="offices__maps">
      <h3>{props.item.title}</h3>
      <p>{props.item.dir}</p>
      <iframe src={props.item.link} title={props.item.title} width="80%" height="600" frameBorder="0" aria-hidden="false" tabIndex="0"></iframe>
    </div>
  )
}

export default Maps

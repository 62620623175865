import React from "react";
import PropTypes from "prop-types";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
const PaypalButton = ({ createOrder, onApprove, onError }) => {
  const initialOptions = {
    "client-id":
      "Ab-jXQUL3mfdIOZXjJFJ2ILO7LbC9XKI0AGOFBvEDbQ5IAeTq_9EsEonvikF1AyJXlbLtZfTESny3Bhr",
    currency: "MXN",
  };
  return (
    <PayPalScriptProvider options={initialOptions}>
      <PayPalButtons
        createOrder={createOrder}
        onApprove={onApprove}
        onError={onError}
      />
    </PayPalScriptProvider>
  );
};
PaypalButton.propTypes = {
  createOrder: PropTypes.func.isRequired,
  onApprove: PropTypes.func.isRequired,
  onError: PropTypes.func,
};
export default PaypalButton;

import { types } from "../types/types";

/**Acción async para conectar a la API*/

/**
 *
 * @param {numeric} id
 * @param {String} type
 * @param {String} tc
 * @param {String} type_user
 * @param {String} username
 * @returns El estado en redux del auth.
 */
export const login = (id, type, tc, username) => ({
  type: types.login,
  payload: {
    user: {
      id,
      type,
      tc,
      username,
    },
  },
});
export const loginUser = (id, type, username, suc_clave) => ({
  type: types.loginUser,
  payload: {
    user: {
      id,
      type,
      username,
      suc_clave,
    },
  },
});
export const logout = () => ({
  type: types.logout,
});

import React from 'react'
/**
 * Esta fucntión convierte las caracteristicas a arreglo para poder renderizarlos en la pagina de Productos
 * @param {*} text Recibe caracteristicas del Modelo
 */
 //var text = caract.map((e) => e.pm_caracteristicas);

 export default function Caracteristicas({text}) {
    let array = [];
    var j = 0;
    var aux = 0;
    for (var i = 0; i < 300; i++) {
        aux++;
        if (text[aux] === '.') {
            array[j] = text.substring(0, aux + 1);
            text = text.slice(aux + 1);
            j++;
            aux = 0;
        }
    }
    return (
        <>
        <h5>Características</h5>
        {
            array.map((elemento) => {
                return (
                    <span key={elemento}>{elemento}<br /></span>
                )
            })
        }
        </>
    );
}

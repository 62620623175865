import React from 'react'
import { useEffect } from 'react'
import './Contact.css'
import ContactForm from './ContactForm'


const Contact = () => {
  useEffect(()=>{
    document.title = 'Contacto'
    window.scrollTo(0,0);
  },[])
  return (
    <div className="contact__container">
      <div className='contact__title'>Contactanos</div>
      <div className='contact__main'>
        <div className='contact__main--img'>
          <img src="https://www.bancoazteca.com.mx/content/dam/azteca/sitio/2019/ayuda/linea_azteca/linea_azteca_900x487.jpg" alt="Contactanos" />
        </div>
        <div className='contact__info'>
          <h4>SoftSimphony-ISiA</h4>
          <p>
            Con Contacto ISIA podrás optimizar tu tiempo, enviando tus dudas por correo o desde tu teléfono; las 24 horas, los 365 días del año.
          </p>
          <p>
            Desde cualquier parte: (55)-7095-3472
          </p>
        </div>
      </div>
      <ContactForm/>
    </div>
  )
}

export default Contact

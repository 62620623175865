import React, { useContext, useEffect, useState } from "react";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormHelperText,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { addToCartPet } from "../../store/actions/cart";
import styled from "styled-components";
import { Controller, useForm } from "react-hook-form";
import { SocketContext } from "../../socket/socket";
import { getChange, validarNum, validarStock } from "../../helpers/Prices";

function Addcart({ model, code, price }) {
  const dispatch = useDispatch();
  const socket = useContext(SocketContext);
  const items = useSelector((state) => state.cart.items);
  const cambio = useSelector((state) => state.cart.cambio);
  const Pcambio = useSelector((state) => state.cart.Pcambio);
  const { handleSubmit, control, reset } = useForm({
    mode: "all",
    defaultValues: {
      sucursal: "",
      cantidad: "",
    },
  });
  const [open, setOpen] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [almacen, setAlmacen] = useState([]);
  const [stock, setStock] = useState(0);
  const [enQ, setEnQ] = useState(true);

  useEffect(() => {
    socket.emit("[client][prod] viewstock", code, (res) => {
      setAlmacen(res);
    });
  }, [code, socket]);
  useEffect(() => {
    if (open) {
      socket.on("[server][inv] viewstock_update", (update) => {
        socket.emit("[client][prod] viewstock", code, (res) => {
          setAlmacen(res);
        });
      });
    }
    return () => {
      socket.off();
    };
  }, [open, code, socket]);

  const handleClose = () => {
    setOpen(false);
    setEnQ(true);
    reset();
    setStock(0);
  };
  const handleCloseSnack = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const handlerOnChange = (e) => {
    const sucursal = e.target.value;
    if (sucursal !== "") {
      const stock = almacen.filter((item) => item.sucursal_id === sucursal);
      setStock(stock[0].stock);
      setEnQ(false);
    } else {
      setEnQ(true);
      setStock(0);
    }
  };
  const onSubmit = (data, e) => {
    e.preventDefault();
    if (!cambio) {
      const price2 = parseFloat(getChange(price, Pcambio)).toFixed(2);
      dispatch(
        addToCartPet(
          code,
          stock,
          model.code,
          model.img,
          data.sucursal,
          parseInt(data.cantidad),
          price2,
          model.description
        )
      );
    } else {
      const price3 = parseFloat(price).toFixed(2);
      dispatch(
        addToCartPet(
          code,
          stock,
          model.code,
          model.img,
          data.sucursal,
          parseInt(data.cantidad),
          price3,
          model.description
        )
      );
    }
    handleClose();
  };
  const openDialog = () => {
    if (items[code]) {
      //checa si el producto ya esta en el carrito
      setOpenSnack(true);
    } else {
      setOpen(true);
    }
  };
  return (
    <Container>
      <Button
        onClick={openDialog}
        variant="contained"
        endIcon={<AddShoppingCartIcon />}
      >
        Agregar
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          Agregar al carrito <AddShoppingCartIcon />
        </DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <DialogContentText>
              Para agregar al carrito el producto es necesario que seleccione la
              sucursal y la cantidad que necesite.
            </DialogContentText>
            <InputContainer>
              <Controller
                control={control}
                name="sucursal"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <SelectContainer>
                    <Select
                      {...field}
                      size="small"
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      onChange={(e) => {
                        field.onChange(e);
                        handlerOnChange(e);
                      }}
                      error={!!error}
                    >
                      <MenuItem value="">Seleccione una sucursal</MenuItem>
                      {almacen.map(
                        (item) =>
                          item.stock !== 0 && (
                            <MenuItem
                              key={item.sucursal_id}
                              value={item.sucursal_id}
                            >
                              {item.sucursal}
                            </MenuItem>
                          )
                      )}
                    </Select>
                    {error ? (
                      <FormHelperText error>Sucursal Requerida</FormHelperText>
                    ) : null}
                  </SelectContainer>
                )}
                rules={{
                  validate: {
                    suc: (value) => value !== "",
                  },
                }}
              />
              <Item>
                <Controller
                  control={control}
                  name="cantidad"
                  type="number"
                  defaultValue="1"
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <TextField
                        {...field}
                        size="small"
                        disabled={enQ}
                        label="Cantidad"
                        variant="outlined"
                        error={!!error}
                        helperText={
                          error?.type === "cantidad"
                            ? "Cantidad requerida"
                            : error?.type === "cantidadNum"
                            ? "Cantidad debe ser mayor a 0"
                            : error?.type === "vStock"
                            ? `Cantidad debe ser menor a ${stock}`
                            : error?.type === "pattern" && "Cantidad no valida"
                        }
                        inputProps={{ maxLength: 3, pattern: "[0-9]*" }}
                      />
                      {stock ? (
                        <FormHelperText>
                          Piezas disponibles: {stock}
                        </FormHelperText>
                      ) : null}
                    </>
                  )}
                  rules={{
                    validate: {
                      cantidad: (value) => value !== "",
                      cantidadNum: (value) => validarNum(value),
                      vStock: (value) => validarStock(value, stock),
                    },
                    pattern: /^[0-9]/,
                  }}
                />
              </Item>
            </InputContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button type="submit">Agregar</Button>
          </DialogActions>
        </form>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={openSnack}
        autoHideDuration={4000}
        onClose={handleCloseSnack}
      >
        <Alert
          onClose={handleCloseSnack}
          variant="filled"
          severity="error"
          sx={{ width: "100%" }}
        >
          El producto ya ha sido añadido.
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default Addcart;

/**
 * Seccion de estilos.
 */
const Container = styled.div`
  display: flex;
  margin: 25px;
`;
const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  @media (max-width: 544px) {
    flex-direction: column;
  }
`;
const Item = styled.div`
  @media (max-width: 544px) {
    margin-top: 20px;
  }
`;
const SelectContainer = styled.div`
  flex-direction: column;
`;

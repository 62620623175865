import PropTypes from "prop-types";
import styled from "styled-components";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Container
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <ContainerItem>{children}</ContainerItem>}
    </Container>
  );
}
const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const ContainerItem = styled.div`
  flex-direction: "row";
  width: 100%;
  height: 100%;
`;
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
export default TabPanel;

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { Container } from "@mui/system";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { API } from "../../../CONFIG";
import { ContainerTable } from "../Inventario/styles";
const headerOrder = [
  {
    id: "CustomerId",
    label: "IdC",
    align: "left",
    style: {
      fontSize: "18px",
      fontWeight: "bold",
    },
  },
  {
    id: "OrderId",
    label: "Id",
    align: "left",
    style: {
      fontSize: "18px",
      fontWeight: "bold",
    },
  },
  {
    id: "Tmoneda",
    label: "Tipo de moneda",
    align: "left",
    style: {
      fontSize: "18px",
      fontWeight: "bold",
    },
  },
  {
    id: "Tcambio",
    label: "Cambio de divisa",
    align: "left",
    style: {
      fontSize: "18px",
      fontWeight: "bold",
    },
  },
  {
    id: "Subtotal",
    label: "Subtotal",
    align: "center",
    style: {
      fontSize: "18px",
      fontWeight: "bold",
    },
  },
  {
    id: "IVA",
    label: "IVA",
    align: "center",
    style: {
      fontSize: "18px",
      fontWeight: "bold",
    },
  },
  {
    id: "Total",
    label: "Total",
    align: "center",
    style: {
      fontSize: "18px",
      fontWeight: "bold",
    },
  },
  {
    id: "status",
    label: "Status",
    align: "center",
    style: {
      fontSize: "18px",
      fontWeight: "bold",
    },
  },
  {
    id: "Fecha",
    label: "Fecha",
    align: "center",
    style: {
      fontSize: "18px",
      fontWeight: "bold",
    },
  },
];

function Orders() {
  const [data, setData] = useState([]);
  const history = useHistory();
  useEffect(() => {
    Axios.get(`${API}/order/view/all`).then((res) => {
      setData(res.data);
    });
    return () => {};
  }, []);
  const onClickItem = (id) => {
    history.push("/dashboard/pedidos/detail", id);
  };
  return (
    <Container>
      <h4 align="center">Pedidos</h4>
      <ContainerTable>
        <Tooltip title="Seleccione un elemento para ver el detalle del pedido.">
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {headerOrder.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={column.style}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((data, index) => {
                return (
                  <TableRow
                    hover
                    key={data.ped_id}
                    onClick={() => onClickItem(data.ped_id)}
                  >
                    <TableCell>{data.ped_fk_user}</TableCell>
                    <TableCell>{data.ped_id}</TableCell>
                    <TableCell>{data.ped_moneda}</TableCell>
                    <TableCell>{data.ped_divisa}</TableCell>
                    <TableCell>{data.ped_subtotal}</TableCell>
                    <TableCell>{data.ped_iva}</TableCell>
                    <TableCell>{data.ped_total}</TableCell>
                    <TableCell>{data.ped_status}</TableCell>
                    <TableCell>{data.ped_date}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Tooltip>
      </ContainerTable>
    </Container>
  );
}

export default Orders;

import { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Axios from "axios";
import { useSelector } from "react-redux";
import { API } from "../CONFIG";
import styled from "styled-components";
import OrderList from "../components/OrderList";
const OrdersScreen = () => {
  const [isLoaded, setIsLoaded] = useState(true);
  const clientId = useSelector((state) => state.auth.user.id);
  const [pedidos, setPedidos] = useState([]);
  useEffect(() => {
    Axios.get(`${API}/order/view/${clientId}`)
      .then((res) => {
        setPedidos(res.data);
        setIsLoaded(false);
      })
      .catch((e) => {
        setIsLoaded(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoaded) {
    return (
      <LoaderContainer>
        <h3 align="center">Tus Pedidos</h3>
        <CircularProgress />
      </LoaderContainer>
    );
  }
  return (
    <>
      {pedidos.length === 0 ? (
        <div>
          <h3 align="center">Tus Pedidos</h3>
          <h3 align="center">No ha realizado ningun pedido</h3>
        </div>
      ) : (
        <OrderList data={pedidos} />
      )}
    </>
  );
};
const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export default OrdersScreen;

import React from 'react'
import {Link} from 'react-router-dom'
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import './Footer.css';
const Footer = () => {

  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__row">
          <div className="footer__solutions">
            <h5>Soluciones Destacadas</h5>
            <Link className='link' to='/products/2'><span>Motores para puertas corredizas</span></Link>
            <Link className='link' to='/products/5'><span>Barrera electromecánicas</span></Link>
            <Link className='link' to='/products/6'><span>Emisores y receptores</span></Link>
          </div>
        </div>
        <div className="footer__row">
          <div className="footer__erreka">
            <h5>Grupo ERREKA</h5>
            <a href="http://www.erreka.mx" target='_blank' rel="noreferrer" id='link_underlined'><span>Página Erreka México</span></a>            
            <div><span><a href='/downloads/all-start.pdf' target='_blank' id="link2"> <h5>ALL-STAR</h5> </a></span></div>
            <div><span><a href='/downloads/FOLLETO-EXPO.pdf' target='_blank' id="link2"> <h5>FOLLETO</h5> </a></span></div>
          </div>
        </div>

        <div className="footer__row">
          <div className="footer__contact">
            <h5>Contacto</h5>
            <Link className='link' to='/'><span>Teléfono: (55)-7095-3477</span></Link>
            <Link className='link' to='/'><span>Teléfono: (55)-7095-3472</span></Link>
            <Link className='link' to='/contacto'><span>Envianos un e-mail</span></Link>
          </div>
          <div className="footer__socialmedia">
            <h5>Siguenos en nuestras redes</h5>
            <div className="redes__sociales">
              <a href="https://www.facebook.com/isia.automatismos" target='_blank' rel="noreferrer"><FacebookIcon></FacebookIcon></a>
              <a href="https://www.youtube.com/channel/UCs25fCUBD7lVDO61Xyc7l7Q" target='_blank' rel="noreferrer"><YouTubeIcon></YouTubeIcon></a>
              <a href="https://www.instagram.com/isia.automatismos/?hl=es" target='_blank' rel="noreferrer"> <InstagramIcon></InstagramIcon></a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer__copyright">
        <div><span>© 2021 ISiA una marca de SoftSimphony |</span></div>
        <div><span><a href='/downloads/Cómo%20deshabilitar%20las%20cookies_marketing.pdf' target='_blank' id="link2"> Politica de Cookies |</a></span></div>
        <div><span><a href='/downloads/AVISO%20DE%20PRIVACIDAD_marketing.pdf'  target='_blank' id="link2"> Aviso de Privacidad |</a></span></div>
        <div><span><a href='/downloads/Políticas de garantía_220830.pdf'  target='_blank' id="link2"> Política de Garantía </a></span></div>
      </div>
    </footer>
  )

}

export default Footer

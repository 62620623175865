import { Link } from "react-router-dom"
import styled from "styled-components"

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`
const ListItem = styled.li`
  padding: 8pt 0 !important;
  margin: 0 !important;
`
function DropDownLinkListLink({ to, label }) {
  return (
    <Link
      to={to}
      activeStyle={{
        fontWeight: "bold",
        color: "black",
      }}
    >
      <span>{label}</span>
    </Link>
  )
}

function DropDownLinkList({ links }) {
  const linkComponents = links.map(({ to, label }) => {
    return (
      <ListItem>
        <DropDownLinkListLink
          to={to}
          label={label}
        />
      </ListItem>
    )
  })

  return (
    <List >
      {linkComponents}
    </List>
  )
}

export default DropDownLinkList
import React from 'react'
import { Link } from 'react-router-dom'
import './Buttons.css'

const Buttons = () => {
  return (
    <div className="buttons__container" align="center">
        <div className="animCont"><Link className="item" to="/products/1" style={{fontSize:'15px'}}>Batientes<p className="puertaCont"><span className="pbizq"></span><span className="pbder"></span></p></Link></div>
        <div className="animCont"><Link className="item" to="/products/2" style={{fontSize:'15px'}}>Corredizas<p className="puertaCont"><span className="pcorr"></span></p></Link></div>
        <div className="animCont"><Link className="item" to="/products/3" style={{fontSize:'15px'}}>Seccionales<p className="puertaCont"><span className="psecc"><span className="ppanel"></span><span className="ppanel"></span><span className="ppanel"></span><span className="ppanel"></span></span></p></Link></div>
        <div className="animCont"><Link className="item" to="/products/4" style={{fontSize:'15px'}}>Enrollables<p className="puertaCont"><span className="prmov"><span className="ppanel"></span><span className="ppanel"></span><span className="ppanel"></span><span className="ppanel"></span></span><span className="prfij"></span></p></Link></div>
        <div className="animCont"><Link className="item" to="/products/5" style={{fontSize:'15px'}}>Barreras<p className="puertaCont"><span className="pbhor"></span><span className="pbver"></span></p></Link></div>
        <div className="animCont"><Link className="item" to="/products/6" style={{fontSize:'15px'}}>Radio<p className="puertaCont"><span className="control"></span><span className="led"></span><span className="onda"></span></p></Link></div>
        <div className="animCont"><Link className="item" to="/products/7" style={{fontSize:'15px'}}>Electrónica<p className="puertaCont"><span className="tarjeta"></span><span className="rect1"></span><span className="rect2"></span><span className="rect3"></span><span className="rect4"></span></p></Link></div>
        {/* <div className="animCont"><Link className="item" to="/products?query=Accesorios">Accesorios</Link></div> */}
    </div>
  )
}

export default Buttons

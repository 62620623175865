
import './TablaInventario.css'
function calcularSuma(data){
    if(data.length!==0){
        const stock=data.map((item)=>item.stock);
    return stock.reduce((stock, newStock)=>(parseInt(stock)+parseInt(newStock)));
    }else{
        return 0;
    }
}

function TablaInventario({data}){
    if(data.length===0){
        return(<h5>Producto no disponible en el inventario</h5>)
    }
    return (
    <div className='tabla_modal'>
        <div >
            <h3>Inventarios</h3>
        </div>
        <div>
        <table className='table'>
                <thead>
                <tr>
                    <th className='t__header' >Sucursal</th>
                    <th className='t__header' >Stock</th>
                </tr>
                </thead>
                <tbody>
                    {
                        data.map((item,index)=> (
                        <tr key={index}>
                            <td className='t__body' >{item.sucursal}</td>
                            <td className='t__body' >{item.stock}</td>
                        </tr>
                        ))
                    }
                </tbody>
                <tfoot>
                    <tr>
                        <th className='t__header' >Total</th>
                        <th className='t__header' >{calcularSuma(data)}</th>
                    </tr>
                </tfoot>
            </table>
        </div>
            
    </div>
    );
}

export default TablaInventario;
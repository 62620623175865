import React from 'react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import Slider from 'react-slick';
import styled from 'styled-components';


function ImgSlider(props) {

    let settings ={
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
    };

    return (
        <Carousel {...settings}>                        
             <Wrap>
                <a href='#header'>
                    <img src='/img/CARRU_6.png' alt=""/>
                </a>
            </Wrap>
            <Wrap>
                <a href='#header'>
                    <img src='/img/CARRU_5.jpg' alt=""/>
                </a>
            </Wrap>
            <Wrap>
                <a href='#header'>
                    <img src='/img/CARRU_1.jpg' alt=""/>
                </a>
            </Wrap>           
            <Wrap>
                <a href='#header'>
                    <img src='/img/CARRU_2.jpg' alt=""/>
                </a>
            </Wrap>            
            
        </Carousel>
    )
}

export default ImgSlider;


const Carousel = styled(Slider)`
    margin-top: 10px;

    & > button{
        opacity: 0;
        height: 100%;
        width: 5vw;
        z-index: 2;
        &:hover{
            opacity: 1;
            transition: opacity 0.2s ease 0s;
        }
    }

    ul li button {  
        &:before{
            font-size: 10px;
            color: rgba(150, 158, 171);
        }
    }

    li.slick-ative button:before{
        color: white;
    }

    .slick-list{
        overflow: initial;
    }

    .slick-prev{
        left: -50px
    }
    .slick-next{
        right: -50px
    }
`;

const Wrap = styled.div`
    border-radius: 4px; 
    cursor: pointer;
    position:relative;

    a{
        border-radius: 4px;
        box-shadow: rgb(0 0 0 / 30%) 0px 26px 30px -10px,
        rgb(0 0 0 / 40%) 0px 16px 10px -10px;
        display: block;
        position: relative; 
        padding: 4px;

        img{
        width: 100%;
        height: 100%;
        }
    }
`;
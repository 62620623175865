import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";
function About() {
  const isMounted = useRef(true);
  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <Aboutus>
      <Nosotros>
        <Fondo></Fondo>
        <Container>
          <Title color="white">SOBRE NOSOTROS</Title>
          <Text color="white">
            Somos un equipo de profesionales dedicado al diseño, fabricación e
            instalación de sistemas para la automatización de entradas y control
            de accesos automáticos.
          </Text>
          <Buttons>
            <Button
              color="white"
              to="#mision"
              scroll={(el) => el.scrollIntoView({ behavior: "smooth" })}
            >
              Misión
            </Button>
            <Button
              color="white"
              to="#vision"
              scroll={(el) => el.scrollIntoView({ behavior: "smooth" })}
            >
              Visión
            </Button>
            <Button
              color="white"
              to="#valores"
              scroll={(el) => el.scrollIntoView({ behavior: "smooth" })}
            >
              Valores
            </Button>
          </Buttons>
        </Container>
      </Nosotros>
      <Details color="white" id="mision">
        <Mision>
          <Title color="black">Misión</Title>
          <Text color="blac">
            Crear soluciones de automatización de manera personalizada para
            empresas e individuos (clientes finales) interesados en la seguridad
            y automatización así como capacitación a nuestros clientes
            instaladores promoviendo una cultura de autoempleo a través de
            medios audiovisuales y espacios virtuales.
          </Text>
          <Buttons2>
            <Button color="black" to="#vision" smooth>
              Visión
            </Button>
            <Button color="black" to="#valores" smooth>
              Valores
            </Button>
          </Buttons2>
        </Mision>
        {isMounted && (
          <Objeto>
            <model-viewer
              id="modelViewer"
              src="./modelos/Motor.glb"
              alt="A 3D model of a motor"
              ar
              camera-orbit=" 65deg 50deg 2.5m"
              shadow-intensity="1"
              shadow-softness="0.5"
              auto-rotate
              camera-controls
            ></model-viewer>
          </Objeto>
        )}
      </Details>
      <Details color="#eeee" id="vision">
        {isMounted && (
          <Objeto>
            <model-viewer
              id="modelViewer"
              src="./modelos/Bástago.glb"
              alt="A 3D model of a motor"
              ar
              camera-orbit=" 65deg 100deg 2.5m"
              shadow-intensity="1"
              shadow-softness="0.5"
              auto-rotate
              camera-controls
            ></model-viewer>
          </Objeto>
        )}

        <Vision>
          <Title color="black">Visión</Title>
          <Text color="black">
            Ser reconocidos a nivel nacional como una empresa de seguridad y
            automatización mediante soluciones innovadoras y el uso de las
            tecnologías de la información y comunicación con estándares de
            seguridad y calidad hacia nuestros clientes, además expandir
            nuestros servicios a nivel internacional cooperando con fabricantes
            de automatización para hacer crecer la comunidad de usuarios.
          </Text>
          <Buttons2>
            <Button color="black" to="#mision" smooth>
              Misión
            </Button>
            <Button color="black" to="#valores" smooth>
              Valores
            </Button>
          </Buttons2>
        </Vision>
      </Details>
      <Details color="white" id="valores">
        <Valores>
          <Title color="black">Valores</Title>
          <Text2>•Calidad</Text2>
          <Text>
            Garantizamos un servicio con estándares de calidad enfocándonos en
            su eficacia y eficiencia.
          </Text>
          <Text2>•Responsabilidad</Text2>
          <Text>
            Tomamos las necesidades del cliente como principal objetivo,
            responsabilizandonos de solventar sus necesidades en tiempo y forma.
          </Text>
          <Text2>•Compromiso</Text2>
          <Text>
            Nos centramos en el cumplimiento de las necesidades del cliente y
            nos comprometemos a brindar un servicio de calidad.
          </Text>
          <Text2>•Educación</Text2>
          <Text>
            Creemos en la cultura del autoempleo por lo que apoyar la
            capacitación y crecimiento personal se encuentra en nuestras
            principales prioridades.
          </Text>
          <Text2>•Trabajo en equipo</Text2>
          <Text>
            Trabajamos en conjunto para reunir actitudes y aptitudes
            profesionales con el objetivo de brindar un servicio de máxima
            calidad.
          </Text>
          <Buttons2>
            <Button color="black" to="#mision" smooth>
              Misión
            </Button>
            <Button color="black" to="#vision" smooth>
              Visión
            </Button>
          </Buttons2>
        </Valores>
      </Details>
      <Contacto>
        <Content>
          <h4>Habla con nuestros especialistas</h4>
        </Content>

        <img src="/img/contacto.jpg" alt="" />
        {/** TODO: cambiar la imagen */}
        <Fondo></Fondo>
        <Enlace to="/contact">
          <EmailIcon />
          CONTACTO
        </Enlace>
        <Enlace2
          href={"https://wa.me/?text=Quiero%20obtener%20informacion"}
          target="_blank"
        >
          <WhatsAppIcon />
          MENSAJE
        </Enlace2>
      </Contacto>
    </Aboutus>
  );
}

export default About;

const Aboutus = styled.div`
  margin: 0;
  width: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
`;

const Nosotros = styled.div`
  background-image: url("https://access.erreka.com/wp-content/uploads/2020/11/erreka-quienes-somos.jpg"); //TODO: cambiar la imagen
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Container = styled.div`
  width: 70%;
  display: flex;
  margin-top: -5%;
  height: fit-content;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  -webkit-animation-name: fadeDown;
  animation-name: fadeDown;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  @keyframes fadeDown {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  @media (max-width: 768px) {
    width: 90%;
    align-items: center;
  }
`;

const Fondo = styled.div`
  position: absolute;
  inset: 0;
  background-color: #193073;
  opacity: 0.5;
  width: 100%;
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  margin: 20px 0px;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    /* flex-direction: column; */
  }
`;

const Buttons2 = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: space-around;
  margin: 10px 0px;
  padding-bottom: 65px;
  @media (max-width: 768px) {
    display: flex;
  }
`;

const Button = styled(HashLink)`
  background-color: transparent;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;
  color: ${(props) => props.color};
  text-decoration: none;
  border: 1px solid ${(props) => props.color};
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  scroll-behavior: smooth;
  font-size: 20px;
  padding: 5px 20px;
  :hover {
    background-color: white;
    color: black;
  }
  @media (max-width: 768px) {
    margin: 10px;
  }
`;

const Title = styled.h2`
  letter-spacing: 3px;
  font-weight: 800;
  color: ${(props) => props.color};
  font-size: 40px;
  margin-bottom: 10px;
  z-index: 2;
  @media (max-width: 768px) {
    text-align: center;
  }
`;
const Text = styled.span`
  letter-spacing: 1px;
  font-size: 18px;
  z-index: 2;
  text-align: center;
  color: ${(props) => props.color};
  margin: 10px 0px;
  margin-bottom: 10px;
`;

const Text2 = styled.span`
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 20px;
  z-index: 2;
  text-align: center;
  color: ${(props) => props.color};
  margin: 0px 0px;
  margin-bottom: 0px;
`;

const Details = styled.div`
  margin: 0px 0px;
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.color};
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Mision = styled.div`
  display: flex;
  width: 50%;
  /* margin-top: -10%; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  @media (max-width: 768px) {
    width: 90%;
  }
`;
const Objeto = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  #modelViewer {
    height: 80%;
    overflow-x: hidden;
    margin-bottom: -20%;
  }
  @media (max-width: 768px) {
    margin-bottom: 7%;
  }
`;
const Vision = styled.div`
  display: flex;
  width: 60%;
  /* margin-top: -10%; */
  flex-direction: column;
  align-items: center;
  z-index: 1;
  @media (max-width: 768px) {
    width: 90%;
  }
`;

const Valores = styled.div`
  background-image: url("/img/BG_Valores.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  display: flex;
  width: 60%;
  /* margin-top: -10%; */
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    width: 90%;
    height: 100%;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    height: auto;
    width: 50%;
    margin-right: 15px;
  }
`;

const Contacto = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 200px;
  position: relative;
  overflow: hidden;
  img {
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    z-index: -2;
  }
  a {
    margin: 0;
    text-decoration: none;
    color: black;
    display: flex;
    align-items: center;
    font-size: 14px;
    letter-spacing: 2.5px;
    font-weight: 700;
    padding: 0px 15px;
    border-radius: 10px;
    background-color: white;
    transition: all 0.5s;
    z-index: 2;
    .MuiSvgIcon-root {
      width: 25px;
      margin-right: 15px;
      margin-top: -17px;
      margin-bottom: -17px;
      transition: all 0.5s;
    }
    :hover {
      background-color: black;
      color: white;
      .MuiSvgIcon-root {
        color: white;
      }
    }
  }
  h4 {
    font-size: 20px;
    color: black;
    letter-spacing: 2px;
  }
  @media (max-width: 768px) {
    margin: 240px 0 0 0;
    display: flex;
    flex-direction: column;
  }
`;

const Enlace = styled(Link)`
  text-decoration: none;
  color: black;
  font-size: 16px;
  height: 20%;
  display: flex;
  align-items: center;
  background-color: white;
  font-weight: 700;
  letter-spacing: 2.5px;
  border-radius: 15px;
  z-index: 2;
  .MuiSvgIcon-root {
    width: 40px;
    margin-right: 15px;
    color: black;
  }
  :hover {
    color: white;
    background-color: black;
    .MuiSvgIcon-root {
      color: white;
    }
  }
`;
const Enlace2 = styled.a`
  text-decoration: none;
  color: black;
  font-size: 16px;
  height: 20%;
  display: flex;
  align-items: center;
  background-color: white;
  font-weight: 700;
  letter-spacing: 2.5px;
  border-radius: 15px;
  z-index: 2;
  .MuiSvgIcon-root {
    width: 40px;
    margin-right: 15px;
    color: black;
  }
  :hover {
    color: white;
    background-color: black;
    .MuiSvgIcon-root {
      color: white;
    }
  }
`;

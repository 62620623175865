export const cartFormat = (cart) => {
  const formated = [];
  for (let i = 0; i < cart.length; i++) {
    const item = cart[i];
    formated.push({
      id: item.id,
      model: item.model,
      sucursal: item.sucursal,
      price: item.price,
      quantity: item.quantity,
      sum: item.sum,
      description: item.description,
    });
  }
  return formated;
};

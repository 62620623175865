import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import Axios from 'axios';
import {useParams} from 'react-router-dom'
import {Link} from 'react-router-dom';
import {API} from '../CONFIG';

function Familia(props) {
    const {id} = useParams();
    const [familia, setfamilia] = useState([]);
    const [modelos, setModelos] = useState([]);
    useEffect(()=>{
        document.title = 'Productos'
      },[]);
    useEffect(() => {
        window.scrollTo(0,0);
        Axios.get(`${API}/familia/${id}`)
        .then((response) => {
            setfamilia(response.data);
        });
        Axios.get(`${API}/modelo/familia/${id}`)
        .then((response) => {
            setModelos(response.data);
        })
    }, [id]);

    return (
        <Container>
            <Path>            
                <Direccion to='/'>Inicio |</Direccion>
                <Direccion to='/products/'>Familias |</Direccion>
            </Path>
            <Content>
                <img src={familia.map((e) => e.fam_imageurl)} alt="Imagen Familia"/>
                <Wrap> 
                    <SubTitle color='black'>{familia.map((e) => e.fam_tipo)}</SubTitle>
                    <TextTitle color='black'><p>{familia.map((e) => e.fam_descripcion)}</p></TextTitle>
                    <Botons>
                        <a href="#products"><Boton color='black'>Productos</Boton></a>
                    </Botons>
                </Wrap>
            </Content>
            
            <Products id='products'>
                {modelos.map((e) => {
                    return(
                        <Product key={e.pm_codigo}>
                            <img src={e.pm_img_url} alt=""/>
                            <Title>{e.pm_modelo}</Title>
                            <Text color='black'>{e.pm_descripcion}</Text>
                            <Link style={{fontSize:'50px'}} to={'/products/'+ familia.map((e) => e.fam_id) + '/' + e.pm_codigo}><Boton color='black'>Descubre más</Boton></Link>
                        </Product>
                    )
                })}
            </Products>
        </Container>
    )
}

export default Familia;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: left;
   
    margin: 3% 20px;
`;

const Text = styled.span`
    font-size: 18px;
    letter-spacing: 1px;
    color: ${props => props.color};
`;

const TextTitle = styled.span`
    font-size: 18px;
    width: 60%;
    letter-spacing: 1px;
    color: ${props => props.color};
    @media(max-width:768px){
        width: 80%;
        text-align: center;
    }
`;
const Path = styled.div`
    display: flex;
    
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    margin: 10px 0px;
`;

const Direccion = styled(Link)`
    text-decoration: none;
    font-size: 12px;
    margin-right: 15px;
    font-weight: 700;
    color: black;
    transition: all 0.5s;
    :hover{
        color: #193073;
    }
`;

const Content = styled.div`
    display: flex;
    justify-content: center;
    min-height: calc(100vh - 170px);
    margin-bottom: 40px;
    width: 100%;
    align-items: center;
    position: relative;
    overflow: hidden;
    img{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0.5;
    }
`;

const Wrap = styled.div`
    display: flex; 
    width: 100%;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    margin: 0px 6vh;
    z-index:2;
    a{
        text-decoration: none;
        transition: all 0.5s;
    }
    @media(max-width:768px){
        margin: 0 0vh;
        align-items: center;
    }
`;

const SubTitle = styled.h4`
    margin: 0px;
    margin: 20px 0px;
    font-size: 50px;
    color: white;
    color: ${props => props.color};
`;

const Botons = styled.div`
    display: flex;
    align-items: center;
    position: relative;
`;


const Products = styled.div`
    margin: 50px 0px;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(4, minmax(0, 1fr));

    @media(max-width:874px){
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

`;

const Title = styled.h5`
    font-size: 14px;
    font-weight: 600;
`;

const Boton = styled.span`
    padding: 8px 18px;
    margin-right: 25px;
    border: 2px solid ${props => props.color}; 
    color: ${props => props.color};
    transition: all 0.5s;
    font-weight: 700;
    font-size: 18px;

    :hover{
        color: white;
        background-color: black;
    }

`;

const Product = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px 5px;
    padding-left:10px;
    a{
        text-decoration: none;
    }
    box-shadow: rgb(0 0 0 / 50%) 0px 26px 30px -10px,
        rgb(0 0 0 / 50%) 0px 16px 10px -10px;
`;
import React, { useContext, useEffect, useState } from "react";
import { Modal, Button, Box } from "@mui/material";
import styled from "styled-components";
import TablaInventario from "./TablaInventario";
import { SocketContext } from "../../socket/socket";
import { memo } from "react";

const InventarioModal = memo(({ codigo, stock }) => {
  const socket = useContext(SocketContext);
  const [modal, setModal] = useState(false);
  const [data, setData] = useState([]);
  //Efecto para obtener la información del inventario.
  useEffect(() => {
    if (codigo !== "default") {
      socket.emit("[client][prod] viewstock", codigo, (res) => {
        setData(res);
        stock(res);
      });
    }
  }, [codigo, socket, stock]);
  //Efecto para actualizar la información del inventario.
  useEffect(() => {
    if (codigo !== "default") {
      socket.on("[server][inv] viewstock_update", (update) => {
        socket.emit("[client][prod] viewstock", codigo, (res) => {
          setData(res);
          stock(res);
        });
      });
    }
    return () => {
      socket.off();
    };
  }, [socket, codigo, stock]);

  function abrirCerrar() {
    setModal(!modal);
  }
  const body = (
    <ContenedorModal>{data && <TablaInventario data={data} />}</ContenedorModal>
  );
  return (
    <div>
      <Box sx={{ "& button": { fontSize: 20 } }}>
        <Button size="small" onClick={() => abrirCerrar()}>
          Ver inventario
        </Button>
      </Box>

      <Modal open={modal} onClose={abrirCerrar}>
        {body}
      </Modal>
    </div>
  );
});

const ContenedorModal = styled.div`
  max-height: 500px;
  position: absolute;
  background-color: white;
  border: 2px solid #000;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  padding: 16px 32px 24px;
  //Posicionar la pantalla
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: auto;
`;

export default InventarioModal;

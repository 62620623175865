import {createStore, combineReducers, applyMiddleware,compose} from 'redux';
import thunk from 'redux-thunk';
import {authReducer} from './reducers/authReducer';
import { cartReducer } from './reducers/cartReducer';


//Para usar la extencion como middelware 
const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
//Combina diferentes reducers
const reducers=combineReducers({
    auth: authReducer,
    cart: cartReducer
});
//Crea el storage con los reducers y aplica el middelware de thunk para hacer acciones asincronas.
export const store= createStore(
    reducers,
    composeEnhancers(
        applyMiddleware(thunk)
    )
    );

import React from 'react'
import ReactDOM from 'react-dom'
import { CSSTransition } from 'react-transition-group'

import './SideMenu.css'

const SideMenu = props => {
  const content = (
    <CSSTransition in={props.show} timeout={200} classNames="menu">
      <aside className="side-menu" onClick={props.onClick}> 
        {props.children} 
      </aside>
    </CSSTransition>
  )
  return ReactDOM.createPortal(content, document.getElementById('menu'))

} 

export default SideMenu
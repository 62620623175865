// API

export const API = "https://isia-api.herokuapp.com/api/v1";
export const SOCKETAPI = "isia-api.herokuapp.com/";
//export const API = "http://localhost:3001/api/v1";
//export const SOCKETAPI = "localhost:3001";
//Token para divisas.
export const TOKEN =
  "03773e2cfcb83eec2887c9c2bce7eb4aaf1c94f16a22fb2aedb5be0b9e7c260b";

//Configuración del banner
export const bannerImages = [
  {
    img: "/img/img1.png",
  },
  {
    img: "/img/img2.png",
  },
  {
    img: "/img/img3.png",
  },
  {
    img: "/img/img4.png",
  },
  {
    img: "/img/img5.png",
  },
  {
    img: "/img/img6.png",
  },
  {
    img: "/img/img7.png",
  },
  {
    img: "/img/img8.png",
  },
  {
    img: "/img/img9.png",
  },
];

export const MD = [
  {
    title: "Sucursal Centro CDMX",
    dir: "Matamoros 11-507-A, Col. Guerrero, Cuauhtémoc, CDMX, C.P 06300. Teléfonos: (55) 62515156, (55) 70953477, (55) 70953472. Horario de atención: Lunes -Viernes 9am-6pm",
    link: "https://maps.google.com/maps?q=Matamoros%2011,%20Guerrero,%20Cuauht%C3%A9moc,%2006300%20Ciudad%20de%20M%C3%A9xico,%20CDMX&t=&z=13&ie=UTF8&iwloc=&output=embed",
  },
  {
    title: "Sucursal Ocotlán, Puebla",
    dir: "Residencial Los Cántaros, San Francisco Ocotlán, De Las Flores, Ocotlán, Puebla, C.P 72680. Teléfonos: (55) 2964 4454, (55) 2964 4454. Horario de atención: Lunes-Viernes 8am - 2pm Después de las dos previa cita",
    link: "https://maps.google.com/maps?q=Residencial%20Los%20C%C3%A1ntaros,%20San%20Francisco%20Ocotl%C3%A1n,%20De%20Las%20Flores,%20San%20Francisco%20Ocotl%C3%A1n,%2072680%20San%20Francisco%20Ocotl%C3%A1n,%20Pue.&t=&z=13&ie=UTF8&iwloc=&output=embed",
  },
  {
    title: "Sucursal Nezahualcóyotl",
    dir: "Oriente 5 216, Col. Reforma, Nezahualcóyotl, Edo. Méx. C.P. 57840, Teléfonos: (55) 8566 3302 (55) 7095 3477, (779)106 6435. Horario de atención: Lunes -Viernes 9am-6pm",
    link: "https://maps.google.com/maps?q=Calle%20Ote%205%20216,%20Reforma,%2057840%20Nezahualc%C3%B3yotl,%20M%C3%A9x.&t=&z=13&ie=UTF8&iwloc=&output=embed",
  },
  {
    title: "Sucursal Valle de bravo",
    dir: "Carretera el Arco Colorines s/n, Valle del bravo. Edo de Méx. CP. 51200. Teléfonos: 51200, (55) 33618326, (59) 59552691. Horario de atención: Lunes -Viernes 9am-6pm",
    link: "https://maps.google.com/maps?q=19%C2%B013'22.3%22N%20100%C2%B007'58.0%22W&t=&z=17&ie=UTF8&iwloc=&output=embed",
  },
  {
    title: "Sucursal Texcoco",
    dir: "Calle Allende 102, Local A, Col. Centro, Texcoco, Edo. Méx C.P 56100. Teléfonos: (595) 9517 1199, (55) 3361 8326. Horario de atención: L-V 9am-6pm, sábado 10am-3pm",
    link: "https://maps.google.com/maps?q=C.%20Allende%20102,%20Centro,%2056100%20Centro,%20M%C3%A9x.&t=&z=19&ie=UTF8&iwloc=&output=embed",
  },
  {
    title: "Sucursal Ecatepec",
    dir: "San Cristóbal centro col la Mora calle palmas esquina con avenida insurgentes. Teléfonos: (55) 2880 7875, (55) 4334 9242, (55) 7095 3477, (779) 1066 435. Horario de atención: Lunes -Viernes 9am-5pm",
    link: "https://maps.google.com/maps?q=San%20Crist%C3%B3bal%20Centro,%2055000%20Ecatepec%20de%20Morelos,%20M%C3%A9x.&t=&z=19&ie=UTF8&iwloc=&output=embed",
  },
  {
    title: "Sucursal Atizapán",
    dir: "Calle Clavelinas N°9-A. Col. Lomas de San Miguel Atizapán de Zaragoza, Estado De México. C.P. 52928. Teléfonos: (55)4205 9147, (55) 52200451, (55) 5220 0452, (55) 7095 3477. Horario de atención: Lunes -Viernes 9am-6pm",
    link: "https://maps.google.com/maps?q=Clavelinas%209,%20Lomas%20de%20San%20Miguel,%2052928%20Cd%20L%C3%B3pez%20Mateos,%20M%C3%A9x.&t=&z=17&ie=UTF8&iwloc=&output=embed",
  },
  {
    title: "Sucursal Querétaro",
    dir: "Laurel # 215 Colonia: Praderas del Sol Municipio: San Juan del Río, Querétaro. Teléfonos: (442) 372 8655 (55) 7095 3477 (779) 1066 435. Horario de atención: Lunes -Viernes 9am- 7pm",
    link: "https://maps.google.com/maps?q=Laurel%20215,%20Praderas%20del%20Sol,%2076804%20San%20Juan%20del%20R%C3%ADo,%20Qro.&t=&z=17&ie=UTF8&iwloc=&output=embed",
  },
];

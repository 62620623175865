import { useState } from "react";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import "./Dropdown.css";
import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";

function Dropdown({ label, children }) {
  const [isOpen, setOpen] = useState(false);

  // TODO: Using conditional rendering is NOT ideal but works for this example
  let menu, overlay;
  if (isOpen) {
    menu = (
      <div className="-x-mui-dropdown-container">
        <div className="-x-mui-dropdown-menu">
          {children}
        </div>
      </div>
    );
  }
  const dropdownIcon = (isOpen && <ArrowDropUp className="-x-mui-dropdown-icon" />) || <ArrowDropDown className="-x-mui-dropdown-icon"/>;

  return (
    <div className="-x-mui-dropdown" onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
      <Tooltip title={label} placement="top">
        <Link variant="text" className="-x-mui-dropdown-btn">
          <div>
            <span>{label}</span>
            {dropdownIcon}
          </div>
        </Link>
      </Tooltip>
      {menu}
    </div>
  );
}

export default Dropdown;

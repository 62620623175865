import React, { useState } from "react";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Button,
  CircularProgress,
  Dialog,
  FormHelperText,
  IconButton,
  MenuItem,
  Select,
  Slide,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import { useEffect } from "react";
import Axios from "axios";
import { API } from "../CONFIG";
import uuid from "react-uuid";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const InvoiceDialog = ({ open, onClose, action, clientId }) => {
  const [cfdi, setCfdi] = useState([]);
  const { handleSubmit, setValue, control, reset } = useForm({
    mode: "all",
    defaultValues: {
      fullname: "",
      email: "",
      rfc: "",
      dir: "",
      cfdi: "",
      rs: "",
      rf: "",
    },
  });
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (open) {
      Axios.get(`${API}/auth/client/register/cfdi`).then((response) => {
        delete response.data[2];
        setCfdi(response.data);
      });
      Axios.get(`${API}/facturacion/check/info/${clientId}`)
        .then((res) => {
          const invoiceData = res.data[0];
          setValue(
            "fullname",
            invoiceData.cli_nombre +
              " " +
              invoiceData.cli_app +
              " " +
              invoiceData.cli_apm
          );
          setValue("email", invoiceData.cli_correo);
          if (invoiceData.cli_rfc !== null) {
            setValue("rfc", invoiceData.cli_rfc);
          }
          if (invoiceData.cli_direccion !== null) {
            setValue("dir", invoiceData.cli_dir);
          }
          if (
            invoiceData.cli_fk_cfdi !== null ||
            invoiceData.cli_cfdi !== "P01"
          ) {
            setValue("cfdi", invoiceData.cli_cfdi);
          }
          if (invoiceData.cli_rs !== null) {
            setValue("rs", invoiceData.cli_rs);
          }
          if (invoiceData.cli_rf !== null) {
            setValue("rf", invoiceData.cli_rf);
          }
        })
        .catch((e) => {});
      setIsLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  const handlerClose = () => {
    reset();
    onClose();
  };
  const onSubmit = (data, e) => {
    e.preventDefault();
    const newData = {
      ...data,
      id: uuid(),
    };
    action(newData);
    handlerClose();
  };
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handlerClose}
      TransitionComponent={Transition}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handlerClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Formulario de facturación
            </Typography>
            <Button type="submit" autoFocus color="inherit">
              Enviar
            </Button>
          </Toolbar>
        </AppBar>

        {isLoading ? (
          <LoaderContainer>
            <CircularProgress />
          </LoaderContainer>
        ) : (
          <FormContainer>
            <FormRow>
              <FormItem>
                <Controller
                  control={control}
                  name="fullname"
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      size="small"
                      type="name"
                      label="Nombre Completo"
                      variant="outlined"
                      error={!!error}
                      helperText={
                        error?.type === "name"
                          ? "Nombre Completo requerido"
                          : error?.type === "pattern" &&
                            "Nombre Completo no valido"
                      }
                      inputProps={{ maxLength: 100 }}
                    />
                  )}
                  rules={{
                    validate: {
                      name: (value) => value !== "",
                    },
                    pattern:
                      /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u,
                  }}
                />
              </FormItem>

              <FormItem>
                <Controller
                  control={control}
                  name="email"
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      size="small"
                      type="email"
                      label="Correo Electrónico"
                      variant="outlined"
                      error={!!error}
                      helperText={
                        error?.type === "email"
                          ? "Correo requerido"
                          : error?.type === "pattern" && "Correo no valido"
                      }
                      inputProps={{ maxLength: 30 }}
                    />
                  )}
                  rules={{
                    validate: {
                      email: (value) => value !== "",
                    },
                    pattern:
                      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g,
                  }}
                />
              </FormItem>
              <FormItem>
                <Controller
                  control={control}
                  name="rfc"
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      size="small"
                      type="text"
                      label="RFC"
                      variant="outlined"
                      error={!!error}
                      helperText={
                        error?.type === "rfc"
                          ? "RFC requerido"
                          : error?.type === "pattern" && "RFC no valido"
                      }
                      inputProps={{ maxLength: 13 }}
                    />
                  )}
                  rules={{
                    validate: {
                      rfc: (value) => value !== "",
                    },
                    pattern: /^[A-Z-0-9]+$/g,
                  }}
                />
              </FormItem>
              <FormItem>
                <Controller
                  control={control}
                  name="dir"
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      size="small"
                      type="text"
                      label="Dirección con Código postal"
                      variant="outlined"
                      error={!!error}
                      helperText={
                        error?.type === "dir"
                          ? "Dirección requerida"
                          : error?.type === "pattern" && "Dirección no válida"
                      }
                      inputProps={{ maxLength: 90 }}
                    />
                  )}
                  rules={{
                    validate: {
                      dir: (value) => value !== "",
                    },
                    pattern: /^[#.0-9a-zA-Z\s,-]+$/,
                  }}
                />
              </FormItem>
              <FormItem>
                <Controller
                  control={control}
                  name="cfdi"
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <Select
                        size="small"
                        {...field}
                        displayEmpty
                        style={{ minWidth: 210 }}
                        error={!!error}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value="">
                          <em>CFDI</em>
                        </MenuItem>
                        {cfdi.map((value) => (
                          <MenuItem
                            key={value.cfdi_codigo}
                            value={value.cfdi_codigo}
                          >
                            {value.cfdi_descripcion}
                          </MenuItem>
                        ))}
                      </Select>
                      {error ? (
                        <FormHelperText error>CFDI Requerido</FormHelperText>
                      ) : null}
                    </>
                  )}
                  rules={{
                    validate: {
                      cfdi: (value) => value !== "",
                    },
                  }}
                />
              </FormItem>
              <FormItem>
                <Controller
                  control={control}
                  name="rs"
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      size="small"
                      type="text"
                      label="Razón Social"
                      variant="outlined"
                      error={!!error}
                      helperText={
                        error?.type === "rs"
                          ? "Razón Social requerida"
                          : error?.type === "pattern" &&
                            "Razón Social no valida"
                      }
                      inputProps={{ maxLength: 50 }}
                    />
                  )}
                  rules={{
                    validate: {
                      rs: (value) => value !== "",
                    },
                    pattern:
                      /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u,
                  }}
                />
              </FormItem>
              <FormItem>
                <Controller
                  control={control}
                  name="rf"
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      size="small"
                      type="text"
                      label="Regimen Fiscal"
                      variant="outlined"
                      error={!!error}
                      helperText={
                        error?.type === "rf"
                          ? "RF requerido"
                          : error?.type === "pattern" && "RF no valido"
                      }
                      inputProps={{ maxLength: 50 }}
                    />
                  )}
                  rules={{
                    validate: {
                      rf: (value) => value !== "",
                    },
                    pattern:
                      /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u,
                  }}
                />
              </FormItem>
            </FormRow>
          </FormContainer>
        )}
      </form>
    </Dialog>
  );
};
InvoiceDialog.propType = {
  clientId: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  action: PropTypes.func.isRequired,
};
const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const FormRow = styled.div`
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
`;
const FormItem = styled.div`
  padding: 15px;
`;
const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default InvoiceDialog;

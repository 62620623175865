import { Link } from "react-router-dom";
import styled from "styled-components";

const ThanksScreen = () => {
  return (
    <Container>
      <div>
        <h4>Gracias por haber comprado con nosotros</h4>
        <Link to="/orders">Ver Pedidos</Link>
      </div>
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export default ThanksScreen;

import { Alert } from "@mui/material";
import React from "react";
import Orders from "../Componentes/Pedidos/Orders";

function PedidosScreen() {
  return (
    <div>
      <Alert icon={false} severity="success">
        PEDIDOS PENDIENTES !!
      </Alert>
      <Orders />
    </div>
  );
}

export default PedidosScreen;

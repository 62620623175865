import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

function Marcas() {
  return (
    <Container>
      <h4>Marcas Destacadas</h4>
      <Content>
        <Wrap>
          <img src="/img/alhua.png" alt="Alhua logo" />
        </Wrap>
        <Wrap>
          <img src="/img/duo-smart.png" alt="Duo smart logo" />
        </Wrap>
        <Wrap>
          <img src="/img/HID.png" alt="HID logo" width="60%" />
        </Wrap>
        <Wrap>
          <img src="/img/Logitech-logo.png" alt="Logitech logo" />
        </Wrap>
        <Wrap>
          <img src="/img/suprema-logo.png" alt="suprema logo" />
        </Wrap>
        <Wrap>
          <img src="/img/UNV_Logo.jpg" alt="UNV logo" />
        </Wrap>
        <Wrap>
          <img src="/img/vorago.png" alt="Vorago logo" />
        </Wrap>
        <Wrap>
          <img src="/img/wulian-logo.png" alt="Wulian logo" />
        </Wrap>
        <Wrap>
          <img src="/img/ZKTeco-logo.png" alt="ZCTeco logo" />
        </Wrap>
      </Content>

      <Tmarcas>
        <Link to="/marcas">
          <h4>Todas las marcas</h4>
        </Link>
      </Tmarcas>
    </Container>
  );
}

export default Marcas;

const Container = styled.div`
  position: relative;
  h4 {
    font-size: 27px;
  }
  align-items: center;
`;
const Content = styled.div`
  display: grid;
  grid-gap: 20px;
  gap: 20px;
  grid-template-columns: repeat(3, minmax(0, 1fr));

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;
const Wrap = styled.div`
  position: relative;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 20%) 0px 26px 30px -10px,
    rgb(0 0 0 / 20%) 0px 16px 10px -10px;
  cursor: pointer;
  overflow: hidden;
  padding-bottom: 20px;

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    inset: 0px;
    width: 25%;
  }
`;
const Tmarcas = styled.div`
  padding-top: 20px;
  text-align: right;
`;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Axios from "axios";
import { API } from "../../../CONFIG";
import { Controller, useForm } from "react-hook-form";
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { Container, ContainerTable } from "./styles";

const headerMov = [
  {
    id: "id",
    label: "Id",
    align: "right",
    style: {
      fontSize: "18px",
      fontWeight: "bold",
    },
  },
  {
    id: "sucursal",
    label: "Sucursal",
    align: "right",
    style: {
      fontSize: "18px",
      fontWeight: "bold",
    },
  },
  {
    id: "usuario",
    label: "Usuario",
    align: "right",
    style: {
      fontSize: "18px",
      fontWeight: "bold",
    },
  },
  {
    id: "no_factura",
    label: "No.Factura",
    align: "right",
    style: {
      fontSize: "18px",
      fontWeight: "bold",
    },
  },
  {
    id: "fecha",
    label: "Fecha",
    align: "right",
    style: {
      fontSize: "18px",
      fontWeight: "bold",
    },
  },
  {
    id: " no_serie",
    label: "No.Serie",
    align: "right",
    style: {
      fontSize: "18px",
      fontWeight: "bold",
    },
  },
  {
    id: "codigo_prod",
    label: "Código Producto",
    align: "right",
    style: {
      fontSize: "18px",
      fontWeight: "bold",
    },
  },
  {
    id: "descripcion",
    label: "Descripción",
    align: "center",
    style: {
      fontSize: "18px",
      fontWeight: "bold",
    },
  },
  {
    id: "cantidad",
    label: "Cantidad",
    align: "right",
    style: {
      fontSize: "18px",
      fontWeight: "bold",
    },
  },
  {
    id: "mov",
    label: "Mov",
    align: "right",
    style: {
      fontSize: "18px",
      fontWeight: "bold",
    },
  },
];

const Movimientos = ({ mov, user }) => {
  const { handleSubmit, control, reset } = useForm({
    mode: "all",
    defaultValues: {
      no_factura: "",
    },
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [tblEntradas, setTblEntradas] = useState([]);
  const [tblSalidas, setTblSalidas] = useState([]);

  const [id, setId] = useState(0);
  const formatDate = (responseDate) => {
    const [fecha] = responseDate.split("T");
    return fecha;
  };

  const handleClose = () => {
    setId(0);
    reset();
    setOpenDialog(false);
  };
  /**
   * This function is a filter to the in or outs of the Inventory
   * @param {Object} inventory
   */
  const divide = (inventory) => {
    console.log(inventory);
    const entradas = inventory.filter((e) => e.movimineto === 1);
    const salidas = inventory.filter((e) => e.movimineto === 2);
    setTblEntradas(entradas);
    setTblSalidas(salidas);
    setOpenBackdrop(false);
    /*
        Filtros para buscar entradas o salidas.
        const entradasSucursal = entradas.filter(
          (e) => e.sucursal === sucursal
        );
        const salidasSucursal = salidas.filter(
          (e) => e.sucursal === sucursal
        );
        console.log(entradas); //solo entradas
        console.log(salidas); //solo salidas
        console.log(entradasSucursal); //entradas por sucursal
        console.log(salidasSucursal); //Salidas por sucursal
        setTblEntradas(entradasSucursal);
        setTblSalidas(salidasSucursal);
        */
  };
  const onSubmit = (data, e) => {
    e.preventDefault();
    setOpenBackdrop(true);
    Axios.patch(`${API}/inventario/${id}`, {
      no_factura: data.no_factura,
    })
      .then((res) => {
        if (res.data.inserted) {
          divide(res.data.inventory);
        } else {
          //no se realizo el cambio o no existe el id
          setOpenBackdrop(false);
        }
      })
      .catch((e) => {
        //Ha ocurrido un error
        setOpenBackdrop(false);
      });
    reset();
    handleClose();
  };
  const onRowClicked = (id) => {
    setId(id);
    setOpenDialog(true);
  };

  useEffect(() => {
    if (user === 1) {
      //Administrador
      setOpenBackdrop(true);
      Axios.get(`${API}/inventario/admin`).then((response) => {
        divide(response.data);
      });
    }
  }, [mov, user]);
  return (
    <Container>
      {user === 1 &&
        (mov === 1 ? (
          <div>
            <h4>Entradas</h4>
            <ContainerTable>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {headerMov.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={column.style}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tblEntradas.map((inv) => {
                    return (
                      <TableRow
                        hover
                        onClick={() => {
                          onRowClicked(inv.id);
                        }}
                        key={inv.id}
                      >
                        <TableCell>{inv.id}</TableCell>
                        <TableCell>{inv.sucursal}</TableCell>
                        <TableCell>{inv.usuario}</TableCell>
                        <TableCell>{inv.no_factura}</TableCell>
                        <TableCell>{formatDate(inv.fecha)}</TableCell>
                        <TableCell>{inv.no_serie}</TableCell>
                        <TableCell>{inv.cod_prod}</TableCell>
                        <TableCell>{inv.prod_descripcion}</TableCell>
                        <TableCell>{inv.cantidad}</TableCell>
                        <TableCell>{inv.motivo}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </ContainerTable>
          </div>
        ) : (
          <div>
            <h4>Salidas</h4>
            <ContainerTable>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {headerMov.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={column.style}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tblSalidas.map((inv) => {
                    return (
                      <TableRow
                        hover
                        onClick={() => {
                          onRowClicked(inv.id);
                        }}
                        key={inv.id}
                      >
                        <TableCell>{inv.id}</TableCell>
                        <TableCell>{inv.sucursal}</TableCell>
                        <TableCell>{inv.usuario}</TableCell>
                        <TableCell>{inv.no_factura}</TableCell>
                        <TableCell>{formatDate(inv.fecha)}</TableCell>
                        <TableCell>{inv.no_serie}</TableCell>
                        <TableCell>{inv.cod_prod}</TableCell>
                        <TableCell>{inv.prod_descripcion}</TableCell>
                        <TableCell>{inv.cantidad}</TableCell>
                        <TableCell>{inv.motivo}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </ContainerTable>
          </div>
        ))}
      <Dialog open={openDialog} onClose={handleClose}>
        <DialogTitle>Editar</DialogTitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <DialogContentText>
              ¿Desea editar la {mov === 1 ? "entrada" : "salida"} que
              selecciono? El único campo que puede editar es el Número de
              factura (No.Factura).
            </DialogContentText>
            <Controller
              control={control}
              name="no_factura"
              type="text"
              defaultValue=""
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  size="small"
                  label="No.Factura"
                  variant="outlined"
                  error={!!error}
                  helperText={
                    error?.type === "factura"
                      ? "No. Factura no valida."
                      : error?.type === "pattern" && "No. Factura no valida."
                  }
                  fullWidth
                  inputProps={{ maxLength: 200 }}
                />
              )}
              rules={{
                validate: {
                  factura: (value) => value !== "",
                },
                pattern:
                  /^[a-zA-Z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u,
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button type="submit">Editar</Button>
          </DialogActions>
        </form>
      </Dialog>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        Cargando...
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};
Movimientos.propTypes = {
  mov: PropTypes.number.isRequired,
  user: PropTypes.number.isRequired,
};
export default Movimientos;


export const getChange=(mount, change)=>{
    return mount*change;
}
export const validarNum=(valor)=>{
    if(valor!== ''){
        if(!isNaN(valor)){
            const v= parseInt(valor);
            if(v <= 0){
                return false;
            }else if(v>=1){
            return true;
            }
        }else{
            return false;
        }
    }
};
export const validarStock=(valor,stock)=>{
  if(valor!==''){
    const v= parseInt(valor);
    if( v > stock){
      return false;
    }else{
      return true;
    }
  }
}
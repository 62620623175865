import {
  Button,
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { API } from "../../CONFIG";
import { socket } from "../../socket/socket";
import { ContainerTable } from "../Componentes/Inventario/styles";
const headerDetail = [
  {
    id: "prod_code",
    label: "Código de producto",
    align: "left",
    style: {
      fontSize: "18px",
      fontWeight: "bold",
    },
  },
  {
    id: "nom_suc",
    label: "Nombre de sucursal",
    align: "left",
    style: {
      fontSize: "18px",
      fontWeight: "bold",
    },
  },
  {
    id: "precio_unitario",
    label: "Precio unitario",
    align: "left",
    style: {
      fontSize: "18px",
      fontWeight: "bold",
    },
  },
  {
    id: "Cantidad",
    label: "Cantidad",
    align: "left",
    style: {
      fontSize: "18px",
      fontWeight: "bold",
    },
  },
];
function PedidosDetailsScreen() {
  const location = useLocation();
  const history = useHistory();
  const [detalle, setDetalle] = useState([]);
  useEffect(() => {
    Axios.get(`${API}/order/view/detail/${location.state}`)
      .then((res) => {
        setDetalle(res.data);
      })
      .catch((e) => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    socket.on("[client][inv] compra", () => {
      Axios.get(`${API}/order/view/detail/${location.state}`)
        .then((res) => {
          setDetalle(res.data);
        })
        .catch((e) => {});
    });

    return () => {
      socket.offAny();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  const handlePedido = () => {
    Axios.post(`${API}/order/status`, {
      status: "Entregado",
      ped_id: location.state,
    })
      .then((res) => {
        history.replace("/dashboard/pedidos");
      })
      .catch((e) => {});
  };
  return (
    <Container>
      <h4 align="center">Detalle de pedido : {location.state}</h4>
      <ContainerTable>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {headerDetail.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={column.style}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {detalle.map((data, index) => {
              return (
                <TableRow hover key={index}>
                  <TableCell>{data.codigo_producto}</TableCell>
                  <TableCell>{data.nombre_sucursal}</TableCell>
                  <TableCell>{data.precio_unitario}</TableCell>
                  <TableCell>{data.cantidad}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </ContainerTable>
      <div>
        <Button variant="contained" onClick={handlePedido}>
          Entregar Pedido
        </Button>
      </div>
    </Container>
  );
}

export default PedidosDetailsScreen;

import React, { useEffect } from "react";
import styled from "styled-components";

function MarcasScreen() {
  useEffect(() => {
    document.title = "ISIA México | Marcas";
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <h4>Nuestras Marcas y Fabricantes</h4>
      <Content>
        <Item>
          <h3>A</h3>
          <ul>
            <li>3nStar</li>
            <li>ABLOY</li>
            <li>ACCESPRO</li>
            <li>ACCESPRO INSDUSTRIAL</li>
            <li>Acer</li>
            <li>Actek</li>
            <li>ACTI</li>
            <li>ACTISENSE</li>
            <li>ADATA</li>
            <li>ADEMCO (HONEYWELL)</li>
            <li>ADVANCETEC INDUSTRIES INC</li>
            <li>AIRMAR</li>
            <li>AKUVOX</li>
            <li>ALARM</li>
            <li>ALARM CONTROLS-ASSA ABLOY</li>
            <li>ALFATRONICS</li>
            <li>Alhua</li>
            <li>ALLIED TELESIS</li>
            <li>ALLTERCO ROBOTICS EOOD</li>
            <li>ALTAI TECHNOLOGIES</li>
            <li>ALTRONIX</li>
            <li>ALVARADO</li>
            <li>ALVARION</li>
            <li>AMANCO-WAVIN</li>
            <li>AMD</li>
            <li>AMERICAN EARTH ANCHORS</li>
            <li>AMPHENOL</li>
            <li>ANCLO</li>
            <li>ANDREW</li>
            <li>ANDREW/COMMSCOPE</li>
            <li>ANTENA SPECIALISTS</li>
            <li>AOC</li>
            <li>APLICACIONES TECNOLÓGICAS</li>
            <li>APOLLO</li>
            <li>APPLE</li>
            <li>apricor</li>
            <li>ARROW</li>
            <li>Aruba</li>
            <li>Aspel</li>
            <li>ASRock</li>
            <li>ASSA ABLOY</li>
            <li>ASTRON</li>
            <li>Astron corporation</li>
            <li>ASUS</li>
            <li>ATLONA</li>
            <li>AUFIT</li>
            <li>AUTODESK</li>
            <li>AVCOM</li>
            <li>axceze</li>
          </ul>
        </Item>
        <Item>
          <h3>B</h3>
          <ul>
            <li>BalamRush</li>
            <li>BAND IT</li>
            <li>BCDVIDEO</li>
            <li>BCS JAGUAR</li>
            <li>BD SPECTRA</li>
            <li>BEA</li>
            <li>BEE ELECTRONICS INC</li>
            <li>BELAIR NETWORKS</li>
            <li>BELDEN</li>
            <li>BENQ</li>
            <li>BIG FANS</li>
            <li>BioStar</li>
            <li>BIRD</li>
            <li>BIRD TECHNOLOGIES</li>
            <li>BitDefender</li>
            <li>Blackpcs</li>
            <li>BLUEPARROT</li>
            <li>BRAVOLED</li>
            <li>Brother</li>
            <li>BRUKEN ASSA ABLOY</li>
            <li>BUNKER SEGURIDAD</li>
          </ul>
        </Item>
        <Item>
          <h3>C</h3>
          <ul>
            <li>C.NORD</li>
            <li>C-MAP</li>
            <li>Cableado estructurado</li>
            <li>CADEX ELECTRONICS INC</li>
            <li>CAMBIUM NETWORKS</li>
            <li>CAME</li>
            <li>CAMESA</li>
            <li>Canon</li>
            <li>CATUSA</li>
            <li>CDP</li>
            <li>CELWAVE</li>
            <li>CENTURY</li>
            <li>CEREVO</li>
            <li>CES</li>
            <li>CHAROFIL</li>
            <li>CHINT</li>
            <li>CIMARRON TECHNOLOGIES</li>
            <li>CITYLOCKS</li>
            <li>COBHAM</li>
            <li>COBRA</li>
            <li>COMMAX</li>
            <li>COMMSCOPE</li>
            <li>Complet</li>
            <li>COMPUSTAR</li>
            <li>COMPUTAR</li>
            <li>COMUNELLO</li>
            <li>CONCOX</li>
            <li>CONDUMEX</li>
            <li>COOLER MASTER</li>
            <li>CORSAIR</li>
            <li>COTEK</li>
            <li>CPI</li>
            <li>CRESCEND</li>
            <li>CRESCO</li>
            <li>CROW</li>
            <li>CRUCIAL</li>
            <li>CSI</li>
            <li>CURRENT AUDIO</li>
            <li>CYBERPOWER</li>
          </ul>
        </Item>
        <Item>
          <h3>D</h3>
          <ul>
            <li>D-LINK</li>
            <li>DATASHIELD</li>
            <li>DAVID CLARK</li>
            <li>DAYSTAR</li>
            <li>DDTEN</li>
            <li>DECIBEL</li>
            <li>DELL</li>
            <li>DELTA</li>
            <li>DELTA BOX</li>
            <li>DESKCAMERA</li>
            <li>DETEX</li>
            <li>DIAMOND ANTENNA</li>
            <li>DIGIFORT</li>
            <li>DIGIKEY</li>
            <li>DJI</li>
            <li>DKS DOORKING</li>
            <li>DORMAKABA</li>
            <li>DRAY TEK</li>
            <li>DRAYTEK</li>
            <li>Duo Smart</li>
          </ul>
        </Item>
        <Item>
          <h3>E</h3>
          <ul>
            <li>E2V</li>
            <li>EAGLE WARRIOR</li>
            <li>EASYWISP</li>
            <li>EC Line</li>
            <li>ECCO</li>
            <li>ECO GREEN ENERGY</li>
            <li>Ecolink</li>
            <li>ECS</li>
            <li>EDSYN</li>
            <li>EGI AUDIO SOLUTIONS</li>
            <li>ELA INNOVATION</li>
            <li>ELDES</li>
            <li>ELECTRONICS DESIGN</li>
            <li>elgato</li>
            <li>Elikon</li>
            <li>ELK PRODUCTS</li>
            <li>EMR CORPORATION</li>
            <li>ENDURA</li>
            <li>ENERGIZER</li>
            <li>ENERGY SYSTEM</li>
            <li>ENFORA</li>
            <li>ENFORCER SECOLARM</li>
            <li>ENGENIUS</li>
            <li>enson</li>
            <li>EPCOM</li>
            <li>EPCOM INDUSTRIAL</li>
            <li>EPCOM INDUSTRIAL SIGNALING</li>
            <li>EPCOM POWERLINE</li>
            <li>EPCOM PROAUDIO</li>
            <li>EPCOM TITANIUM</li>
            <li>EPEVER</li>
            <li>EPRINT</li>
            <li>EPSON</li>
            <li>ESCORT</li>
            <li>eset</li>
            <li>ETSOLAR</li>
            <li>EVENTIDE</li>
            <li>EVETAR</li>
            <li>EVGA</li>
            <li>EVOROK</li>
            <li>EXIDE</li>
            <li>EXIDE PREVAILER</li>
            <li>EZVIZ</li>
          </ul>
        </Item>
        <Item>
          <h3>F</h3>
          <ul>
            <li>FAAC</li>
            <li>FANVIL</li>
            <li>FEDERAL APD</li>
            <li>FEDERAL SIGNAL</li>
            <li>FEDERAL SIGNAL INDUSTRIAL</li>
            <li>FEDERAL SIGNAL VAMA</li>
            <li>FIBERHOME</li>
            <li>FIBOX</li>
            <li>FIPLEX</li>
            <li>FIRE-LITE</li>
            <li>FIRST ALERT</li>
            <li>FLIR</li>
            <li>FLUKE</li>
            <li>FLUKE NETWORKS</li>
            <li>FOLKSAFE</li>
            <li>FREEDOM COMMUNICATION TECHNOLOGIES</li>
            <li>FREEWAVE</li>
          </ul>
        </Item>
        <Item>
          <h3>G</h3>
          <ul>
            <li>GAITRONICS</li>
            <li>GAMBERJOHNSON</li>
            <li>GAME FACTOR</li>
            <li>GARMIN</li>
            <li>GE</li>
            <li>GEMECO</li>
            <li>GENÉRICO</li>
            <li>GEORGE FOREMAN</li>
            <li>GeoVision</li>
            <li>GETAC</li>
            <li>Gettech</li>
            <li>GEWISS</li>
            <li>GIGABYTE</li>
            <li>GLOBALSTAR</li>
            <li>GNR</li>
            <li>GOLD PEAK</li>
            <li>GOOD 2 GO</li>
            <li>GOOGLE</li>
            <li>GRAND STREAM</li>
            <li>GRANDSTREAM</li>
            <li>GROWATT</li>
            <li>GUARDIAN TRAFFIC SYSTEMS</li>
            <li>GUEST INTERNET</li>
          </ul>
        </Item>
        <Item>
          <h3>H</h3>
          <ul>
            <li>HAKKO</li>
            <li>HANWHA TECHWIN WISENET</li>
            <li>HERTA SECURITY</li>
            <li>HES - ASSA BLOY</li>
            <li>HEWLETT PACKARD ENTERPRISE (HP)</li>
            <li>HIBOOST</li>
            <li>HID</li>
            <li>HIKMICRO</li>
            <li>HIKROBOT</li>
            <li>HILOOK BY HIKVISION</li>
            <li>Hisense</li>
            <li>HKVISION</li>
            <li>HOCHIKI</li>
            <li>Honeywell</li>
            <li>HONEYWELL</li>
            <li>HONEYWELL ANALYTICS</li>
            <li>HONEYWELL BMS</li>
            <li>HONEYWELL FARENHYT SERIES</li>
            <li>HONEYWELL HOME RESIDEO</li>
            <li>HONEYWELL PAVA</li>
            <li>HOYMILES</li>
            <li>HP</li>
            <li>HUBBELL</li>
            <li>HUBITAT INC</li>
            <li>HUSTLER</li>
            <li>HYPERLUX</li>
            <li>HYPERSPIKE</li>
            <li>HYSECURITY</li>
            <li>Hytera</li>
          </ul>
        </Item>
        <Item>
          <h3>I</h3>
          <ul>
            <li>ian</li>
            <li>iHORN</li>
            <li>intel</li>
            <li>INTELLINET</li>
            <li>interlogix</li>
            <li>ISB</li>
          </ul>
        </Item>
        <Item>
          <h3>J</h3>
          <ul>
            <li>JABRA</li>
            <li>JASCO</li>
            <li>JIROUS</li>
            <li>JUPITER</li>
            <li>JVC</li>
            <li>JVC KEENWOOD CORPORATION</li>
            <li>JVC KENWOOD</li>
            <li>JVSG</li>
          </ul>
        </Item>
        <Item>
          <h3>K</h3>
          <ul>
            <li>Kaspersky</li>
            <li>KEENON</li>
            <li>KENWOOD</li>
            <li>KESTER</li>
            <li>KEYSCAN-DORMAKABA</li>
            <li>KHOMP</li>
            <li>Kidde</li>
            <li>KINGSTON</li>
            <li>KLEIN TOOLS</li>
            <li>Koblenz</li>
            <li>KOCOM</li>
          </ul>
        </Item>
        <Item>
          <h3>L</h3>
          <ul>
            <li>L3HARRIS</li>
            <li>L-COM</li>
            <li>LAIRD</li>
            <li>LARSEN</li>
            <li>LARSEN ANTENAS</li>
            <li>LEA INTERNATIONAL</li>
            <li>LEAPTON</li>
            <li>LENOVO</li>
            <li>LG</li>
            <li>LIFTPRO</li>
            <li>LINEAR</li>
            <li>LINKEDPRO BY EPCOM</li>
            <li>LINKEDPRO BY FIBERHOME</li>
            <li>LINKSYS</li>
            <li>LITEONI</li>
            <li>LITLLE GIANT LADDER SYSTEMS</li>
            <li>logitech</li>
            <li>LOUROE ELECTRONICS</li>
            <li>LUMITEC</li>
            <li>LUTRON ELECTRONICS</li>
          </ul>
        </Item>
        <Item>
          <h3>M</h3>
          <ul>
            <li>M2M SERVICES</li>
            <li>MACURCO - AERIONICS</li>
            <li>MAKITA</li>
            <li>manhattan</li>
            <li>MAXELL</li>
            <li>MCDI SECURITY PRODUCTS INC</li>
            <li>MEANWLL</li>
            <li>MEDEC - ASSA ABLOY</li>
            <li>MEITRACK</li>
            <li>MERCUSYS</li>
            <li>Meriva</li>
            <li>MFJ</li>
            <li>MICROLAB</li>
            <li>Microsoft</li>
            <li>MIKROTIK</li>
            <li>MILESTONE SYSTEMS INC</li>
            <li>MIMOSA NETWORKS</li>
            <li>MINI CIRCUITS</li>
            <li>Mobifree</li>
            <li>MORAD</li>
            <li>MORNINGSTAR</li>
            <li>Motorola</li>
            <li>MOUSER ELECTRONICS</li>
            <li>msi</li>
            <li>MTI WIRELESS EDGE</li>
            <li>Multimedia Screens</li>
            <li>MyBusinessPOS</li>
          </ul>
        </Item>
        <Item>
          <h3>N</h3>
          <ul>
            <li>NATIONAL SOFT</li>
            <li>NAVICO INC</li>
            <li>NAVIONICS</li>
            <li>NEDAP</li>
            <li>NEP</li>
            <li>NETONIX</li>
            <li>NETPOINT</li>
            <li>NEWARK</li>
            <li>NEWMAR</li>
            <li>NEXETALK</li>
            <li>NICE</li>
            <li>NIKE</li>
            <li>Nintendo</li>
            <li>Norton</li>
            <li>NOTIFIER</li>
            <li>NUCTECH</li>
            <li>NUUO</li>
            <li>NXRADIO</li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </Item>
        <Item>
          <h3>O</h3>
          <ul>
            <li>OOPENALPR</li>
            <li>OPENVOX</li>
            <li>OPTEX</li>
            <li>OPTOELECTRONICS</li>
            <li>ORBTECH</li>
            <li>OTTO</li>
          </ul>
        </Item>
        <Item>
          <h3>P</h3>
          <ul>
            <li>PANASONIC</li>
            <li>PANAVISE</li>
            <li>PANDUIT</li>
            <li>PARADOX</li>
            <li>Parktron</li>
            <li>Parmon</li>
            <li>PATRIOT</li>
            <li>PCSC</li>
            <li>PCTEL</li>
            <li>PHILLIPS-ASSA ABLOY</li>
            <li>PHOX</li>
            <li>PIMA</li>
            <li>PLANET</li>
            <li>PlayStation</li>
            <li>PLP</li>
            <li>PNY</li>
            <li>POLITEC</li>
            <li>Poly</li>
            <li>POLYPHASER</li>
            <li>POSITIVE CHARGE</li>
            <li>POWER PRODUCTS</li>
            <li>POWER SONIC</li>
            <li>PRECISION</li>
            <li>PRIMUSWINDPOWER</li>
            <li>PRO VISION</li>
            <li>PROSTAR</li>
            <li>PRYME</li>
            <li>PTI</li>
            <li>PULSE LARSEN ANTENNAS</li>
            <li>PYRONIX</li>
          </ul>
        </Item>
        <Item>
          <h3>Q</h3>
          <ul>
            <li>QNAP</li>
          </ul>
        </Item>
        <Item>
          <h3>R</h3>
          <ul>
            <li>RACO</li>
            <li>RADIOWAVES</li>
            <li>RAMSEY</li>
            <li>RANGER SECURITY DETECTORS</li>
            <li>RASILIENT</li>
            <li>RAWELT</li>
            <li>RAYTHEON</li>
            <li>RAZER</li>
            <li>RBTEC</li>
            <li>RCI-DORMAKABA</li>
            <li>resideo</li>
            <li>RF ARMOR</li>
            <li>RF ELEMENTS</li>
            <li>RF INDUSTRIES, LTD</li>
            <li>RF PARTS</li>
            <li>RFS</li>
            <li>RISCO</li>
            <li>RITRON</li>
            <li>ROHN</li>
            <li>Roku</li>
            <li>ROSSLARE SECURITY PRODUCTS</li>
            <li>RUGGEAR</li>
            <li>RUIJIE</li>
            <li>RUIZ ELECTRONICS</li>
            <li>RUPTELA</li>
          </ul>
        </Item>
        <Item>
          <h3>S</h3>
          <ul>
            <li>SAFE FIRE DETECTION INC</li>
            <li>SAFT</li>
            <li>SAILOR</li>
            <li>SAMLEX</li>
            <li>SAMSUNG</li>
            <li>SAMSUNG ELECTRONICS</li>
            <li>SAND DISK</li>
            <li>SAPPHIRE</li>
            <li>SBE TECH</li>
            <li>SDI</li>
            <li>SEAGATE</li>
            <li>SEAGATE</li>
            <li>SECO-LARM</li>
            <li>SECO-LARM USA INC</li>
            <li>SECULLUM</li>
            <li>SECURITRON-ASSA ABLOY</li>
            <li>SENSEAR</li>
            <li>SFIRE</li>
            <li>SGC</li>
            <li>SHAKESPEARE</li>
            <li>SHORE POWER</li>
            <li>SIEMON</li>
            <li>SIGNAL INSIDE</li>
            <li>SIKLU</li>
            <li>SILENT KNIGHT BY HONEYWELL</li>
            <li>SILIMEX</li>
            <li>SIMRAD</li>
            <li>SINCLAIR</li>
            <li>SKB</li>
            <li>SKYLINK</li>
            <li>SKYPATROL</li>
            <li>SKYWORTH</li>
            <li>smartbitt</li>
            <li>SMARTRUNK</li>
            <li>SoftGuard</li>
            <li>SOLARJACK</li>
            <li>SOMFY</li>
            <li>SONOFF</li>
            <li>SONY</li>
            <li>STI</li>
            <li>Stylos</li>
            <li>SUN AMP</li>
            <li>SUN PUMPS</li>
            <li>SUNFORCE</li>
            <li>SUNPOWER</li>
            <li>SURE-FI</li>
            <li>SURTEK</li>
            <li>SYNOLOGY</li>
            <li>SYSCOM</li>
          </ul>
        </Item>
        <Item>
          <h3>T</h3>
          <ul>
            <li>TAKEX</li>
            <li>TAMRON</li>
            <li>TeamViewer</li>
            <li>TECHNITOOL</li>
            <li>TELECOM SECURITY</li>
            <li>TELEWAVE INC</li>
            <li>TELO SYSTEMS</li>
            <li>TELTONIKA</li>
            <li>TEMPO</li>
            <li>Tenda</li>
            <li>TENMA</li>
            <li>TESA- ASSA ABLOY</li>
            <li>THORSMAN</li>
            <li>TIMES MICROWAVE</li>
            <li>TITANIUM INNOVATIONS</li>
            <li>Top Vision</li>
            <li>TOSHIBA</li>
            <li>TOTAL GROUND</li>
            <li>TP-LINK</li>
            <li>TPL COMMUNICATIONS</li>
            <li>TRAM</li>
            <li>TRANSCRYPT</li>
            <li>TRANSTECTOR</li>
            <li>TRIDENT MICRO SYSTEMS</li>
            <li>TRIUMPH</li>
            <li>TRUAUDIO</li>
            <li>TrueBasIX</li>
            <li>TRYLON</li>
            <li>TUF TUG</li>
            <li>TUL MEX</li>
            <li>TWR</li>
            <li>TX PRO</li>
            <li>TX PROMARINE</li>
            <li>TX RX SYSTEMS INC.</li>
            <li>TYCON POWER PRODUCTS</li>
          </ul>
        </Item>
        <Item>
          <h3>U</h3>
          <ul>
            <li>UBIQUITI</li>
            <li>UBIQUITI NETWORKS</li>
            <li>UGREEN</li>
            <li>uniarch</li>
            <li>UNV</li>
            <li>UR FOG</li>
            <li>URREA</li>
            <li>USFULL</li>
            <li>UTEPO</li>
          </ul>
        </Item>
        <Item>
          <h3>V</h3>
          <ul>
            <li>V-SOL</li>
            <li>VARIOS</li>
            <li>VERTEX STANDARD</li>
            <li>VIAKON</li>
            <li>VIAVI</li>
            <li>VICA</li>
            <li>VICTORINOX</li>
            <li>VIDEOCOMM</li>
            <li>VINPOWER DIGITAL</li>
            <li>VORAGO</li>
            <li>VSSL</li>
          </ul>
        </Item>
        <Item>
          <h3>W</h3>
          <ul>
            <li>W&W</li>
            <li>WEJOIN</li>
            <li>WELLER</li>
            <li>WESTERN DIGITAL</li>
            <li>WI-TEK</li>
            <li>WILMORE ELECTRONICS</li>
            <li>WILSONPRO/WEBOOST</li>
            <li>WINLAND ELECTRONICS</li>
            <li>WIREA AND MORE</li>
            <li>WNSTED</li>
            <li>Wulian</li>
          </ul>
        </Item>
        <Item>
          <h3>X</h3>
          <ul>
            <li>XBOX</li>
            <li>XENO</li>
            <li>XPG</li>
            <li>XTRAILS</li>
            <li>XZEAL</li>
          </ul>
        </Item>
        <Item>
          <h3>Y</h3>
          <ul>
            <li>YALE-ASSA ABLOY</li>
            <li>YAMAHA</li>
            <li>YEASTAR</li>
            <li>Yeyian</li>
            <li>YLI ELECTRONIC</li>
            <li>YONUSA</li>
          </ul>
        </Item>
        <Item>
          <h3>Z</h3>
          <ul>
            <li>ZETRON</li>
            <li>ZKTECO</li>
            <li>ZOTAC</li>
            <li>ZYXEL</li>
          </ul>
        </Item>
      </Content>
    </Container>
  );
}

export default MarcasScreen;

const Container = styled.div`
  position: relative;
  h4 {
    font-size: 27px;
  }
  align-items: center;
`;

const Content = styled.div`
  display: grid;
  grid-gap: 20px;
  gap: 20px;
  grid-template-columns: repeat(3, minmax(0, 1fr));

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  padding: 30px;
`;

const Item = styled.div`
  border-radius: 30px;
  height: 100%;
  min-height: 250px;
  width: 70%;
  h3 {
    text-align: center;
  }
  ul {
    padding: 15px;
    list-style: none;
  }
  border-bottom: 30px;
  background-color: silver;
`;

import React, { useEffect, useState } from "react";
import Axios from "axios";
import { Link, useHistory } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { Alert, Backdrop, CircularProgress, TextField } from "@mui/material";
import { API } from "../CONFIG";
import "./Login.css";
import { useDispatch } from "react-redux";
import { login } from "../store/actions/auth";
import styled from "styled-components";

function Login() {
  Axios.defaults.withCredentials = true;
  const { handleSubmit, control } = useForm({ mode: "all" });
  const dispatch = useDispatch();
  const [er, setEr] = useState("");
  const history = useHistory();
  const [openBackdrop, setOpenBackDrop] = useState(false);
  useEffect(() => {
    document.title = "Login";
  }, []);
  const onSubmit = (data, e) => {
    e.preventDefault();
    setOpenBackDrop(true);
    Axios.post(`${API}/auth/client/login`, {
      username: data.username,
      password: data.password,
    })
      .then((response) => {
        if (!response.data.auth) {
          setOpenBackDrop(false);
          setEr(response.data.message);
        } else {
          setEr("");
          const { id, type, tc, username } = response.data.user;
          localStorage.setItem("TOKENISIA", response.data.token);
          setOpenBackDrop(false);
          dispatch(login(id, type, tc, username));
          history.replace("/");
        }
      })
      .catch((e) => {
        setOpenBackDrop(false);
        setEr("Se ha producido un error favor de volver a intentar");
      });
  };

  return (
    <div className="login">
      <Link to="/">
        <img className="login__logo" src="/img/ISiA.png" alt="Logo ISIA" />
      </Link>
      <div className="login__container">
        <h1>Sign in</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormItems>
            <Controller
              control={control}
              name="username"
              defaultValue=""
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  size="small"
                  type="user"
                  label="Usuario"
                  variant="outlined"
                  error={!!error}
                  helperText={error?.type === "text" && "Usuario requerido"}
                  inputProps={{ maxLength: 20 }}
                />
              )}
              rules={{
                validate: {
                  text: (value) => value !== "",
                },
              }}
            />
          </FormItems>

          <FormItems>
            <Controller
              control={control}
              name="password"
              defaultValue=""
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  size="small"
                  type="password"
                  label="Contraseña"
                  variant="outlined"
                  error={!!error}
                  helperText={
                    error?.type === "password" && "Contraseña requerida"
                  }
                  inputProps={{ maxLength: 30 }}
                />
              )}
              rules={{ validate: { password: (value) => value !== "" } }}
            />
          </FormItems>
          {er && (
            <Alert icon={false} variant="filled" severity="error">
              {er}
            </Alert>
          )}
          <Boton>Log In</Boton>
        </form>

        <p>
          By signing-in you agree to ISIA`s Conditions of Use & Sale. Please see
          our Privacy Notice, our Cookies Notice and our Interest-Based Ads
          Notice
        </p>
        <Link to="/register" className="login__registerButton">
          Create your Account
        </Link>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
const Boton = styled.button`
  background: var(--main-font-color);
  border-radius: 2px;
  width: 100%;
  padding: 7px 20px;
  border: 1px solid;
  font-size: 14px;
  margin-top: 10px;
  border-color: #a88734;
  cursor: pointer;
  color: white;
  transition: all 0.4s;
  letter-spacing: 1.5px;

  &:hover {
    background-color: lightgray;
    color: var(--main-font-color);
    border: 1px solid var(--main-font-color);
  }
`;
const FormItems = styled.div`
  width: 100%;
  flex-direction: column;
  padding: 10px;
  font-weight: 500;
  margin-bottom: 20px;
`;

export default Login;

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Axios from "axios";
import { Link } from "react-router-dom";
import { API } from "../CONFIG";

function Familias() {
  const [familia, setfamilia] = useState([]);
  useEffect(() => {
    document.title = "Productos";
  }, []);
  useEffect(() => {
    (async () => {
      await Axios.get(`${API}/familia`).then((response) => {
        setfamilia(response.data);
      });
    })();
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <Link
        style={{
          marginTop: "20px",
          marginBottom: "10px",
          textDecoration: "none",
          color: "black",
        }}
        to="/downloads/Catalogo.pdf"
        target="_blank"
      >
        <Boton type="button"> DESCARGA NUESTRO CATALOGO DE PRODUCTOS </Boton>
      </Link>
      {familia.map((e) => {
        return (
          <Content key={e.fam_id}>
            <img src={e.fam_imageurl} alt="" />
            <Wrap>
              <SubTitle color="black">{e.fam_tipo}</SubTitle>
              <TextTitle color="black">
                <p>{e.fam_descripcion}</p>
              </TextTitle>
              <Link to={"/products/" + e.fam_id}>
                <Boton color="black">Ver productos de esta familia</Boton>
              </Link>
              <p> </p>
              <p> </p>
            </Wrap>
          </Content>
        );
      })}
    </Container>
  );
}

export default Familias;

const Container = styled.div`
  margin: 0px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TextTitle = styled.span`
  font-size: 18px;
  width: 100%;
  letter-spacing: 1px;
  color: ${(props) => props.color};

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const Content = styled.div`
  margin: 10px;
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
  height: 70%;
  width: 100%;
  align-items: center;
  position: relative;
  overflow: hidden;
  img {
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    -webkit-filter: blur(7px);
    filter: blur(7px);
  }
`;

const Wrap = styled.div`
  display: flex;
  width: 90%;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  margin: 0 6vh;
  z-index: 2;
  a {
    text-decoration: none;
    transition: all 0.5s;
  }
  @media (max-width: 768px) {
    margin: 0 0vh;
    align-items: center;
    margin-top: -5vh;
  }
`;

const SubTitle = styled.h4`
  margin: 165px 0 0 0;
  font-size: 50px;
  color: white;
  color: ${(props) => props.color};
`;

const Boton = styled.span`
  padding: 8px 18px;
  margin-right: 25px;
  border: 2px solid ${(props) => props.color};
  color: ${(props) => props.color};
  transition: all 0.5s;
  font-weight: 700;
  font-size: 18px;

  :hover {
    color: white;
    background-color: black;
  }
`;

import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  emptyCart,
  removeToCart,
  updateItem,
  updateStock,
} from "../store/actions/cart";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormHelperText,
  IconButton,
  Snackbar,
  TextField,
} from "@mui/material";
import styled from "styled-components";
import DeleteIcon from "@mui/icons-material/Delete";
import { Controller, useForm } from "react-hook-form";
import { validarNum, validarStock } from "../helpers/Prices";
import { SocketContext } from "../socket/socket";
import { useRef } from "react";
import { API } from "../CONFIG";
import { useHistory } from "react-router-dom";
import PaypalButton from "../components/PaypalButton";
import { cartFormat } from "../helpers/cart";
import InvoiceDialog from "../components/InvoiceDialog";
const CartScreen = () => {
  const socket = useContext(SocketContext);
  const cartItems = useSelector((state) => {
    const transformedCartItems = [];
    const isEmpty = Object.entries(state.cart.items).length === 0;
    if (!isEmpty) {
      for (const key in state.cart.items) {
        transformedCartItems.push({
          id: key,
          model: state.cart.items[key].model,
          description: state.cart.items[key].description,
          sucursal: state.cart.items[key].sucursal,
          sucursalName: state.cart.items[key].sucursalName,
          stock: state.cart.items[key].stock,
          img: state.cart.items[key].img,
          price: state.cart.items[key].price,
          quantity: state.cart.items[key].quantity,
          sum: state.cart.items[key].sum,
        });
      }
    }

    return transformedCartItems;
  });
  const dispatch = useDispatch();
  const codes = useRef([]);
  const [showMessage, setShowMessage] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState("");
  const [openDialog, setOpendialog] = useState(false);
  const [openInvoiceForm, setOpenInvoiceForm] = useState(false);
  const [isInvoice, setIsInvoice] = useState(false);
  const [invoice, setInvoice] = useState({});
  const { control } = useForm({ mode: "onChange" });
  const auth = useSelector((state) => state.auth.user);
  const cambio = useSelector((state) => state.cart);
  const totalAmount = useSelector((state) => state.cart.totalAmount);
  const subtotal = useSelector((state) => state.cart.subtotal);
  const ivaQ = useSelector((state) => state.cart.ivaQ);
  const quantity = useSelector((state) => state.cart.quantity);

  useEffect(() => {}, [cartItems]);
  const history = useHistory();
  const dialogOpenClose = () => {
    setOpendialog(!openDialog);
  };
  const dialogInvoiceOpenClose = () => {
    setOpenInvoiceForm(!openInvoiceForm);
  };
  const getInvoiceData = (data) => {
    setIsInvoice(true);
    setInvoice(data);
  };
  const dialogAction = () => {
    if (auth.type === 3) {
      dialogOpenClose();

      dialogInvoiceOpenClose();
    } else {
      dialogOpenClose();
      history.push("/login");
    }
  };
  const handleClose = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    codes.current = [];
    setShowMessage(false);
    setShowError(false);
    setError("");
  };
  const handlerOnChange = (e, code, stock) => {
    const quantity = e.target.value;
    if (!isNaN(quantity)) {
      if (validarNum(quantity)) {
        if (validarStock(quantity, stock)) {
          dispatch(updateItem(code, parseInt(quantity)));
        }
      }
    }
  };

  const createOrder = (data, actions) => {
    const cart = cartFormat(cartItems);
    const body = { amount: totalAmount, client: auth.id, cart: cart };
    return fetch(`${API}/order/checkout`, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((order) => order.id);
  };
  const onApprove = (data, actions) => {
    const cart = cartFormat(cartItems);
    const body = {
      client: auth.id,
      cart: cart,
      subtotal: subtotal,
      iva: parseFloat(ivaQ).toFixed(2),
      total: totalAmount,
      change: cambio.Pcambio,
      currency: cambio.Tmoneda,
      invoice: invoice,
    };
    return fetch(`${API}/order/payment/${data.orderID}`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((orderData) => {
        console.log(orderData);
        dispatch(emptyCart());
        socket.emit("[client][inv] compra");
        history.push("/thanks");
      });
  };
  const onError = (e) => {
    setError(
      "Ha ocurrido un error favor de intentar de nuevo o refrescar la página"
    );
    setShowError(true);
  };
  useEffect(() => {
    if (quantity !== 0) {
      for (let i = 0; i < cartItems.length; i++) {
        const element = cartItems[i];
        socket.emit("[client][prod] viewstock", element.id, (res) => {
          const stock = res.filter(
            (item) => item.sucursal_id === element.sucursal
          )[0].stock;
          if (stock === 0) {
            codes.current.push(element.id);
            setShowMessage(true);
            dispatch(removeToCart(element.id));
          } else {
            dispatch(updateStock(element.id, stock));
          }
        });
      }
    }
    return () => {
      socket.off();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);
  useEffect(() => {
    socket.once("[server][inv] viewstock_update", (update) => {
      if (quantity !== 0) {
        for (let i = 0; i < cartItems.length; i++) {
          const element = cartItems[i];
          socket.emit("[client][prod] viewstock", element.id, (res) => {
            const stock = res.filter(
              (item) => item.sucursal_id === element.sucursal
            )[0].stock;
            if (stock === 0) {
              codes.current.push(element.id);
              setShowMessage(true);
              dispatch(removeToCart(element.id));
            } else {
              dispatch(updateStock(element.id, stock));
            }
          });
        }
      }
    });
    return () => {
      socket.off();
    };
  }, [socket, quantity, cartItems, dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <h2>Tu carrito</h2>
      <CartContainer>
        {cartItems.map((item) => (
          <React.Fragment key={item.id}>
            <CartItem>
              <CartItemContent
                style={{
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IconButton
                  size="large"
                  color="error"
                  onClick={() => {
                    dispatch(removeToCart(item.id));
                    socket.off("[server][inv] viewstock_update");
                  }}
                >
                  <DeleteIcon fontSize="large" />
                </IconButton>
              </CartItemContent>
              <img src={item.img} alt="Imegn del modelo" />
              <CartItemContent>
                <h3>Modelo: {item.model}</h3>
                <h3>Código: {item.id}</h3>
                <h3>Sucursal: {item.sucursalName}</h3>
                <h3>Descripción: {item.description}</h3>
                <h3>
                  Precio Unitario: ${parseFloat(item.price).toFixed(2)}{" "}
                  {!cambio.cambio ? "MXN" : "USD"}
                </h3>
              </CartItemContent>
              <CartItemContent>
                <h3>
                  Precio: ${parseFloat(item.sum).toFixed(2)}{" "}
                  {!cambio.cambio ? "MXN" : "USD"}
                </h3>
              </CartItemContent>
              <CartItemContent>
                <Controller
                  control={control}
                  name={`cantidadProd ${item.id}`}
                  type="number"
                  defaultValue={`${item.quantity}`}
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <TextField
                        {...field}
                        id="TextQuantity"
                        size="small"
                        label="Cantidad"
                        variant="outlined"
                        error={!!error}
                        onChange={(e) => {
                          field.onChange(e);
                          handlerOnChange(e, item.id, item.stock);
                        }}
                        helperText={
                          error?.type === "cantidad"
                            ? "Cantidad requerida"
                            : error?.type === "cantidadNum"
                            ? "Formato no valido"
                            : error?.type === "vStock"
                            ? `Cantidad debe ser menor a ${item.stock}`
                            : error?.type === "pattern" && "Cantidad no valida"
                        }
                        inputProps={{ maxLength: 3, pattern: "[0-9]*" }}
                      />
                      <FormHelperText>
                        Piezas disponibles: {item.stock}
                      </FormHelperText>
                    </>
                  )}
                  rules={{
                    validate: {
                      cantidad: (value) => value !== "",
                      cantidadNum: (value) => validarNum(value),
                      vStock: (value) => validarStock(value, item.stock),
                    },
                    pattern: /^[0-9]/,
                  }}
                />
              </CartItemContent>
            </CartItem>
            <Divider />
          </React.Fragment>
        ))}
        {quantity !== 0 && (
          <FinalPrice>
            <h3>
              Subtotal: ${parseFloat(subtotal).toFixed(2)}{" "}
              {!cambio.cambio ? "MXN" : "USD"}
            </h3>
            <h3>
              IVA (16%): ${parseFloat(ivaQ).toFixed(2)}{" "}
              {!cambio.cambio ? "MXN" : "USD"}
            </h3>
            <h3>
              Total: ${parseFloat(totalAmount).toFixed(2)}{" "}
              {!cambio.cambio ? "MXN" : "USD"}
            </h3>
          </FinalPrice>
        )}
        {quantity === 0 && (
          <h3 style={{ textAlign: "center" }}>No hay nada en el Carrito</h3>
        )}
        {isInvoice && auth.type === 3 && !cambio.cambio && quantity !== 0 && (
          <PaypalButton
            createOrder={createOrder}
            onApprove={onApprove}
            onError={onError}
          />
        )}
        {!isInvoice &&
          quantity !== 0 &&
          (auth.type === 0 || auth.type === 3) && (
            <ButtonContainer>
              <Button onClick={dialogOpenClose} variant="outlined" size="large">
                Ordenar
              </Button>
            </ButtonContainer>
          )}
        <Dialog
          open={openDialog}
          onClose={dialogOpenClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {auth.type === 0
              ? "Iniciar de sesión"
              : auth.type === 3
              ? "Facturar"
              : "Aviso"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {auth.type === 0
                ? "Es necesario que inicie sesión para poder realizar la compra."
                : auth.type === 3
                ? "¿Desea Facturar?"
                : "No esta permitido ordenar con el usuario actual."}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                dialogOpenClose();
                if (auth.type === 3) {
                  setIsInvoice(true);
                }
              }}
            >
              {auth.type === 3 ? "No, gracias" : "Cancelar"}
            </Button>
            {(auth.type === 0 || auth.type === 3) && (
              <Button
                style={{ display: "flex" }}
                onClick={dialogAction}
                autoFocus
              >
                {auth.type === 3 ? "Facturar" : "Iniciar Sesion"}
              </Button>
            )}
          </DialogActions>
        </Dialog>
        {auth.type === 3 && (
          <InvoiceDialog
            clientId={auth.id}
            open={openInvoiceForm}
            onClose={dialogInvoiceOpenClose}
            action={getInvoiceData}
          />
        )}
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={showMessage}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            variant="filled"
            onClose={handleClose}
            severity="info"
            sx={{ width: "100%" }}
          >
            El producto con codigo {codes.current.toString()} se acaba de
            terminar.
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={showError}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            variant="filled"
            onClose={handleClose}
            severity="error"
            sx={{ width: "100%" }}
          >
            {error}
          </Alert>
        </Snackbar>
      </CartContainer>
    </Container>
  );
};
export default CartScreen;

const Container = styled.div`
  display: flex;

  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  align-items: center;
  flex-direction: column;
  h2 {
    text-align: center;
  }
`;
const CartContainer = styled.div`
  width: 80%;
`;
const CartItem = styled.div`
  display: flex;
  flex-direction: row;

  img {
    width: 30%;
  }
  @media (max-width: 768px) {
    img {
      width: 50%;
    }

    align-items: center;
    flex-direction: column;
  }
`;
const CartItemContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
`;
const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
`;
const FinalPrice = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: end;
`;

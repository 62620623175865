import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import Axios from "axios";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Chip } from "@mui/material";
import { useSelector } from "react-redux";

import Precios from "./Componentes/Precios";
import Caracteristicas from "./Componentes/Caracteristicas";
import InventarioModal from "./Modal/InventarioModal";
import { API } from "../CONFIG";
import Addcart from "./Componentes/Addcart";

function Product() {
  const cambio = useSelector((state) => state.cart.cambio);
  const iva = useSelector((state) => state.cart.iva);
  const tc = useSelector((state) => state.auth.user.tc);
  const [modelo, setModelo] = useState([]);
  const [productos, setProductos] = useState([]);
  const [producto, setProducto] = useState([]);
  const [stock, setStock] = useState(0);
  const [precio, setPrecio] = useState(0);
  const [codigo, setCodigo] = useState("");
  const { id } = useParams();
  const { familia } = useParams();
  useEffect(() => {
    document.title = "Productos";
  }, []);
  useEffect(() => {
    (async () => {
      await Axios.get(`${API}/modelo/codigo/${id}`).then((response) => {
        setModelo(response.data);
      });
      await Axios.get(`${API}/producto/modelo/${id}`).then((response) => {
        setProductos(response.data);
      });
    })();
    window.scrollTo(0, 0);
  }, [id]);

  const getStock = (totalStock) => {
    if (totalStock.length !== 0) {
      const stock = totalStock.map((item) => item.stock);
      setStock(
        stock.reduce((stock, newStock) => parseInt(stock) + parseInt(newStock))
      );
    } else {
      setStock(0);
    }
  };
  const handleModelo = async (e) => {
    if (e.target.value !== "default") {
      await Axios.get(`${API}/producto/info/${e.target.value}`).then(
        (response) => {
          setProducto(response.data);
          setCodigo(e.target.value);
          const prod = response.data.reduce((item) => item);
          if (tc === "" || tc === "CF") {
            //Usuario final o general
            setPrecio(prod.prod_precio_a.toFixed(2));
          } else if (tc === "CI") {
            //Instalador
            setPrecio(prod.prod_precio_b.toFixed(2));
          } else if (tc === "CO") {
            //Consignatario
            const p = prod.prod_precio_b / 1.05;
            setPrecio(p.toFixed(2));
          }
        }
      );
    } else {
      setProducto([]);
      setCodigo("");
      setPrecio(0.0);
    }
  };

  return (
    <Container>
      <Path>
        <Direccion to="/">Inicio |</Direccion>
        <Direccion to={"/products/" + familia}>Familia |</Direccion>
        <Direccion to="/producto/">
          {modelo.map((e) => e.pm_modelo)} |
        </Direccion>
      </Path>
      <Content>
        <img src={modelo.map((e) => e.pm_img_url)} alt="Imagen del Modelo" />
        <Wrap>
          <h4>{modelo.map((e) => e.pm_modelo)}</h4>
          <h5>Codigo del Modelo: {modelo.map((e) => e.pm_codigo)}</h5>
          <h5>Descripción del Modelo</h5>
          <span>{modelo.map((e) => e.pm_descripcion)}</span>
          <Caracteristicas
            text={modelo.map((e) => e.pm_caracteristicas).toString()}
          />
          <div>
            <h5>Productos:</h5>
            <Model>
              <select defaultChecked={"default"} onChange={handleModelo}>
                <option value={"default"}>Selecciona un producto</option>
                {productos.map((e) => {
                  return (
                    <option key={e.prod_codigo} value={e.prod_codigo}>
                      {e.prod_codigo}
                    </option>
                  );
                })}
              </select>
              {codigo ? (
                stock !== 0 ? (
                  <Chip
                    style={{ marginLeft: "20px" }}
                    label="Disponible"
                    color="success"
                  />
                ) : (
                  <Chip
                    style={{ marginLeft: "20px" }}
                    label="Agotado"
                    color="error"
                  />
                )
              ) : null}
            </Model>
            <InventarioModal stock={getStock} codigo={codigo} />
          </div>
          <span width="55%"> {producto.map((e) => e.prod_descripcion)} </span>
          <Precios price={precio} />
          {cambio ? (
            <Note>
              Precio en dólares, activa el botón para ver el precio en pesos
              mexicanos
            </Note>
          ) : (
            <Note>
              Precio en pesos mexicanos, activa el botón para ver el precio en
              dólares
            </Note>
          )}
          {iva ? (
            <Note>
              Precio con IVA, activa el botón para ver el precio sin IVA
            </Note>
          ) : (
            <Note>
              Precio sin IVA, activa el botón para ver el precio con IVA
            </Note>
          )}
          <Note>Precio valido solo para el dia de pago</Note>
          {codigo
            ? stock !== 0 && (
                <Model>
                  <Addcart
                    model={{
                      code: modelo[0].pm_codigo,
                      img: modelo[0].pm_img_url,
                      description: producto[0].prod_descripcion,
                    }}
                    code={codigo}
                    price={precio}
                  />
                </Model>
              )
            : null}
        </Wrap>
      </Content>
      <Contacto>
        <Content>
          <h4>Habla con nuestros especialistas</h4>
        </Content>
        <img src="/img/contacto.jpg" alt="" />
        <Fondo></Fondo>
        <Path>
          <Enlace to="/contact">
            <EmailIcon />
            CONTACTO
          </Enlace>
          <a
            style={{ height: "20%" }}
            href={"https://api.whatsapp.com/send?phone=+525562515156"}
            target="blank"
          >
            <WhatsAppIcon />
            MENSAJE
          </a>
        </Path>
      </Contacto>
    </Container>
  );
}

export default Product;

/*Seccion de estilos*/

const Container = styled.div`
  margin: 20px;
  margin-top: 30px;
`;

const Model = styled.div`
  display: flex;
  /* justify-content: space-around; */
  align-items: stretch;
  width: 100%;
`;

const Path = styled.div`
  display: flex;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0px;
`;

const Note = styled.span`
  padding-left: 10px;
  color: var(--main-font-color);
`;

const Direccion = styled(Link)`
  text-decoration: none;
  font-size: 12px;
  margin-right: 15px;
  font-weight: 700;
  color: black;
  transition: all 0.5s;
  :hover {
    color: #193073;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    height: auto;
    width: 50%;
    margin-right: 15px;
  }
  @media (max-width: 768px) {
    display: inline-block;
    img {
      width: 100%;
    }
  }
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
  margin: 30px 0px;
  h4 {
    margin: 0;
    font-size: 28px;
  }
  span {
    font-size: 14px;
    margin: 2px 0;
    letter-spacing: 1px;
  }
`;

const Contacto = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 200px;
  margin: 30px -20px;
  position: relative;
  overflow: hidden;
  z-index: 2;
  img {
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
    z-index: -2;
  }
  a {
    margin: 0;
    text-decoration: none;
    color: black;
    display: flex;
    align-items: center;
    font-size: 14px;
    letter-spacing: 2.5px;
    margin-left: 10px;
    font-weight: 700;
    padding: 0px 15px;
    border-radius: 10px;
    background-color: white;
    transition: all 0.5s;
    .MuiSvgIcon-root {
      width: 25px;
      margin-right: 15px;
      margin-top: -17px;
      margin-bottom: -17px;
      transition: all 0.5s;
    }
    :hover {
      background-color: black;
      color: white;
      .MuiSvgIcon-root {
        color: white;
      }
    }
  }
  h4 {
    font-size: 20px;
    color: white;
    letter-spacing: 2px;
  }
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`;

const Fondo = styled.div`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: #193073;
  opacity: 0.4;
  z-index: -1;
`;

const Enlace = styled(Link)`
  align-items: center;
  background-color: white;
  border-radius: 15px;
  display: flex;
  color: black;
  height: 20%;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  letter-spacing: 2.5px;

  .MuiSvgIcon-root {
    width: 40px;
    margin-right: 15px;
    color: black;
  }
  :hover {
    color: white;
    background-color: black;
    .MuiSvgIcon-root {
      color: white;
    }
  }
`;

//Para uso en las accines en redux.
export const types = {
  login: "[auth] login",
  loginUser: "[auth] loginUser",
  logout: "[auth] logout",
  addToCart: "[cart] add",
  removeToCart: "[cart] delete",
  updateItem: "[cart] updateItem",
  updateStock: "[cart][product] updateStock",
  iva: "[cart][product] iva",
  cambio: "[cart][product] cambio",
  precios: "[product] precios",
  emptyCart: "[cart] empty",
};

import React, { useState, useEffect } from 'react'
import { TitleLabel } from '../Layout/TitleLabel/TitleLabel'
import Maps from './Maps'
import './Offices.css'
import {MD} from '../CONFIG'


const Offices = () => {
  useEffect(()=>{
    document.title = 'Sucursales'
    window.scrollTo(0,0);
  },[])

  const [map,setMap] = useState(MD[0])

  const changeMapHandler = (index) => {
    setMap(MD[index])
  }

  return (
    <div className="offices__container">
      <TitleLabel icon={<i className="fas fa-map-signs"></i>} label="Visítanos"/>
      <div className="offices__btns">
        <button onClick={()=>changeMapHandler(0)}>{MD[0].title}</button>
        <button onClick={()=>changeMapHandler(1)}>{MD[1].title}</button>
        <button onClick={()=>changeMapHandler(2)}>{MD[2].title}</button>
        <button onClick={()=>changeMapHandler(3)}>{MD[3].title}</button>
        <button onClick={()=>changeMapHandler(4)}>{MD[4].title}</button>
        <button onClick={()=>changeMapHandler(5)}>{MD[5].title}</button>
        <button onClick={()=>changeMapHandler(6)}>{MD[6].title}</button>
        <button onClick={()=>changeMapHandler(7)}>{MD[7].title}</button>
      </div>
      <Maps item={map}/>
    </div>
  )
}

MD[1].title="Sucursal Ocotlán, Puebla";

export default Offices

import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Navbar from "../Dashboard/Componentes/Navbar";
import { DashboardScreen } from "../Dashboard/screens/DashboardScreen";
import { InventarioScreen } from "../Dashboard/screens/InventarioScreen";
import PedidosDetailsScreen from "../Dashboard/screens/PedidosDetailsScreen";
import PedidosScreen from "../Dashboard/screens/PedidosScreen";
import { UsersScreen } from "../Dashboard/screens/UsersScreen";

function DashboardRoutes() {
  return (
    <>
      <Navbar />
      <div>
        <Switch>
          <Route
            exact
            path="/dashboard/inventario"
            component={InventarioScreen}
          />
          <Route exact path="/dashboard/pedidos" component={PedidosScreen} />
          <Route
            exact
            path="/dashboard/pedidos/Detail"
            component={PedidosDetailsScreen}
          />
          <Route exact path="/dashboard/Users" component={UsersScreen} />
          <Route exact path="/dashboard" component={DashboardScreen} />
          <Redirect to="/dashboard" />
        </Switch>
      </div>
    </>
  );
}

export default DashboardRoutes;

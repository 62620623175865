import { Box, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import TabPanel from "../Componentes/Inventario/TabPanel";
import Registro from "../Componentes/Inventario/Registro";
import Movimientos from "../Componentes/Inventario/Movimientos";
import ProductosAlmacen from "../Componentes/Inventario/ProductosAlmacen";
export const InventarioScreen = () => {
  const sucursal = useSelector((state) => state.auth.user.suc_clave);
  const user = useSelector((state) => state.auth.user.type);
  const [tab, setTab] = useState(0);
  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        {user === 1 ? (
          <Tabs value={tab} centered onChange={handleChange}>
            <Tab label="Registrar" {...a11yProps(0)} />
            <Tab label="Entradas" {...a11yProps(1)} />
            <Tab label="Salidas" {...a11yProps(2)} />
            <Tab label="Productos en almacen" {...a11yProps(3)} />
          </Tabs>
        ) : (
          <Tabs value={tab} centered onChange={handleChange}>
            <Tab label="Registrar" {...a11yProps(0)} />
            <Tab label="Productos en almacen" {...a11yProps(1)} />
          </Tabs>
        )}
      </Box>

      {user === 1 ? (
        <>
          <TabPanel value={tab} index={0}>
            <Registro />
          </TabPanel>

          <TabPanel value={tab} index={1}>
            <Movimientos user={user || 0} mov={1} />
          </TabPanel>

          <TabPanel value={tab} index={2}>
            <Movimientos user={user || 0} mov={2} />
          </TabPanel>
          <TabPanel value={tab} index={3}>
            <ProductosAlmacen sucursal={sucursal || ""} />
          </TabPanel>
        </>
      ) : (
        <>
          <TabPanel value={tab} index={0}>
            <Registro />
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <ProductosAlmacen sucursal={sucursal || ""} />
          </TabPanel>
        </>
      )}
    </Box>
  );
};

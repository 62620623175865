import React, { useState } from "react";
import { NavLink, Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setCambio, setIva } from "../../store/actions/cart";

import styled from "styled-components";
import { Badge, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import InventoryIcon from "@mui/icons-material/Inventory";
import BusinessIcon from "@mui/icons-material/Business";
import LoginIcon from "@mui/icons-material/Login";

import { logout } from "../../store/actions/auth";
import "./NavLinks.css";
import Dropdown from "../../components/dropdown/Dropdown";
import DropDownLinkList from "../../components/navlinks/DropDownLinkList";

const NavLinks = ({ menu }) => {
  const [anchorEl, setAnchorEl] = useState(null); //para el menu
  const history = useHistory();
  const cambio = useSelector((state) => state.cart.cambio);
  const iva = useSelector((state) => state.cart.iva);
  const quantity = useSelector((state) => state.cart.quantity);
  const username = useSelector((state) => state.auth.user?.username);
  const dispatch = useDispatch();

  const handleChangeDivisa = () => {
    dispatch(setCambio(!cambio));
  };
  const handleChangeIVA = () => {
    dispatch(setIva(!iva));
  };
  const handlerUser = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null); //para cerrar el menu
  };
  const handlePedidos = () => {
    handleClose();
    history.push("/orders");
  };
  const handleLogout = () => {
    dispatch(logout());
    window.localStorage.removeItem("TOKENISIA");
    history.replace("/");
  };

  return (
    <ul className="nav-links">
      <li>
        <Dropdown label="Modificar Precio">
          <SwitchButton>
            <span>MXN</span>
            <label className="switch">
              <input
                type="checkbox"
                checked={cambio}
                onChange={() => handleChangeDivisa()}
              />
              <span className="slider"></span>
            </label>
            <span>USD</span>
          </SwitchButton>

          <SwitchButton>
            <span> sin I.V.A</span>
            <label className="switch">
              <input
                type="checkbox"
                checked={iva}
                onChange={() => {
                  handleChangeIVA();
                }}
              />
              <span className="slider"></span>
            </label>
            <span>con I.V.A </span>
          </SwitchButton>
        </Dropdown>
      </li>
      <li>
        <Dropdown label="Productos">
          <NavLink
            to="/products"
            activeStyle={{
              fontWeight: "bold",
              color: "black",
            }}
            onClick={menu}
          >
            <div>
              <InventoryIcon />
              <span>Productos Erreka</span>
            </div>
          </NavLink>
        </Dropdown>
      </li>
      <li>
        <Dropdown label="Sucursales">
          <DropDownLinkList
            links={[
              { to: "/offices", label: "Sucursal Centro CDMX" },
              { to: "/offices", label: "Sucursal Ocotlán, Puebla" },
              { to: "/offices", label: "Sucursal Nezahualcóyotl" },
              { to: "/offices", label: "Sucursal Valle de Bravo" },
              { to: "/offices", label: "Sucursal Texcoco" },
              { to: "/offices", label: "Sucursal Ecatepec" },
              { to: "/offices", label: "Sucursal Atizapán" },
              { to: "/offices", label: "Sucursal Querétaro" },
            ]}
          />
        </Dropdown>
      </li>

      <li>
        <Tooltip title="Contacto" placement="top">
          <div>
            <NavLink
              to="/contact"
              activeStyle={{
                fontWeight: "bold",
                color: "black",
              }}
              onClick={menu}
            >
              <span>Contacto</span>
            </NavLink>
          </div>
        </Tooltip>
      </li>

      <li>
        <Tooltip title="Carrito" placement="top">
          <div>
            <NavLink
              to="/cart"
              activeStyle={{
                fontWeight: "bold",
                color: "black",
              }}
              onClick={menu}
            >
              <Badge badgeContent={quantity} color="info">
                <span>Carrito</span>
              </Badge>
            </NavLink>
          </div>
        </Tooltip>
      </li>
      <li>
        <Tooltip title="Misión y Visión" placement="top">
          <div>
            <NavLink
              to="/about#mision"
              activeStyle={{
                fontWeight: "bold",
                color: "black",
              }}
              onClick={menu}
            >
              <span>Misión y Visión</span>
            </NavLink>
          </div>
        </Tooltip>
      </li>
      {username ? (
        <li>
          <IconButton
            size="medium"
            aria-label="Usuario Actual"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handlerUser}
            color="inherit"
          >
            <AccountCircleIcon fontSize="medium" />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>Perfil</MenuItem>
            <MenuItem onClick={handlePedidos}>Pedidos</MenuItem>
            <MenuItem onClick={handleLogout}>Cerrar sesión</MenuItem>
          </Menu>
        </li>
      ) : (
        <li>
          <Tooltip title="Login" placement="top">
            <IconButton
              size="medium"
              aria-label="Login"
              color="inherit"
              onClick={() => {
                history.push("/login");
              }}
            >
              <LoginIcon />
            </IconButton>
          </Tooltip>
        </li>
      )}
    </ul>
  );
};

export default NavLinks;

// TODO: This would be better off as its own component
const SwitchButton = styled.div`
  display: flex;
  align-items: center;
  font-size: 10px;

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 17px;
    margin: 8px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 90%;
    width: 26px;
    left: 2px;
    background-color: white;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
  }

  input:checked + .slider {
    background-color: #142559;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #142559;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;

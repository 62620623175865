import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import Backdrop from "./Backdrop";
import NavLinks from "./NavLinks";
import SideMenu from "./SideMenu";
import "./Header.css";

const Header = () => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [show, handleShow] = useState(false);
  const openMenu = () => {
    setMenuIsOpen(true);
  };
  const closeMenu = () => {
    setMenuIsOpen(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        handleShow(true);
      } else {
        handleShow(false);
      }
    });
    return () => {
      window.removeEventListener("scroll", null);
      handleShow(false);
      setMenuIsOpen(false);
    };
  }, []);

  return (
    <React.Fragment>
      {menuIsOpen && <Backdrop click={closeMenu} />}
      {menuIsOpen && (
        <SideMenu show={menuIsOpen}>
          <nav className="header__menu-nav">
            <NavLinks menu={closeMenu} />
          </nav>
        </SideMenu>
      )}
      <header className={`header ${show && "header__black"}`}>
        <Link to="/">
          <img
            className="header__image"
            src={show ? "/img/ISiA_B.png" : "/img/ISiA.png"}
            alt="Logo ISIA"
          />
        </Link>
        <nav className="header__navigation">
          <NavLinks />
        </nav>
        <button className="header__menu-btn" onClick={openMenu}>
          <MenuIcon></MenuIcon>
        </button>
      </header>
    </React.Fragment>
  );
};

export default Header;

import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  h4 {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
  }
`;
export const ContainerTable = styled.div`
  display: flex;
  flex: 1;
  max-height: 300px;
  flex-direction: column;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  margin: 20px;
  padding: 10px;
  overflow: auto;
`;

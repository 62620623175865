import React from "react";
import styled from "styled-components";

const SplashScreen = () => {
  return (
    <Container>
      <img width="100" height="100" src="/img/ISiA.png" alt="Logo ISiA" />
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;
export default SplashScreen;

import React from 'react'
import './TitleLabel.css'

export const TitleLabel = (props) => {
  return (
    <div className="TitleLabel">
      {props.icon}<p>{props.label}</p>
    </div>
  )
}

import { useEffect, useRef } from 'react';
import { FacebookProvider, CustomChat } from 'react-facebook';
const  ChatFacebook =()=>{
  const isMonted=useRef(true);
  useEffect(() => {
    return () => {
      isMonted.current=false;
    }
  }, [])
  
    return (
      <>
      {
        isMonted && (<FacebookProvider appId="681305646381017" chatSupport>
        <CustomChat pageId="330195490700421" minimized={false}/>
      </FacebookProvider>)
      }
    </>
    );
}
export default ChatFacebook;
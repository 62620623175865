import CartItem from "../models/CartItem";
import { types } from "../types/types";
export const cartReducer = (
  state = {
    quantity: 0,
    items: {},
    totalAmount: 0,
    subtotal: 0,
    ivaQ: 0,
    Tmoneda: "MXN",
    cambio: false,
    iva: false,
    Pcambio: 0.0,
  },
  action
) => {
  switch (action.type) {
    case types.addToCart:
      const addedProduct = action.payload.item;
      const code = addedProduct.code;
      const stock = +addedProduct.stock;
      const sucursal = addedProduct.sucursal;
      const sucursalName = addedProduct.sucursalName;
      const model = addedProduct.model;
      const description = addedProduct.description;
      const img = addedProduct.img;
      const quantity = addedProduct.quantity;
      const price = +addedProduct.price;
      const sum = addedProduct.quantity * price;
      const updatedOrNewCartItem = new CartItem(
        model,
        stock,
        img,
        sucursal,
        price,
        quantity,
        sum,
        description,
        sucursalName
      );
      let totalAmountPrev;
      let subtotal;
      let ivaqP;
      if (state.iva) {
        subtotal = state.subtotal + sum;

        ivaqP = subtotal * 0.16;
        totalAmountPrev = subtotal * 1.16;
      } else {
        subtotal = state.subtotal + sum;
        ivaqP = 0;
        totalAmountPrev = subtotal;
      }
      return {
        ...state,
        quantity: state.quantity + 1,
        items: { ...state.items, [code]: updatedOrNewCartItem },
        subtotal: subtotal,
        ivaQ: ivaqP,
        totalAmount: totalAmountPrev,
        Tmoneda: state.Tmoneda,
      };

    case types.updateItem:
      const cartItems1 = { ...state.items };
      const codigo = action.payload.code;
      const cantidad = +action.payload.quantity;
      const suma = parseFloat(cartItems1[codigo].price).toFixed(2) * cantidad;
      const updatedItem = {
        ...state.items,
        [codigo]: { ...state.items[codigo], quantity: cantidad, sum: suma },
      };
      const subtotal4 = state.subtotal - cartItems1[codigo].sum + suma;
      let ivaqP4;
      let totalAmountP4;
      if (state.iva) {
        ivaqP4 = subtotal4 * 0.16;
        totalAmountP4 = subtotal4 * 1.16;
      } else {
        ivaqP4 = 0;
        totalAmountP4 = subtotal4;
      }
      if (state.items[codigo]) {
        return {
          ...state,
          items: updatedItem,
          subtotal: subtotal4,
          ivaQ: ivaqP4,
          totalAmount: totalAmountP4,
        };
      } else {
        return { state };
      }

    case types.removeToCart:
      const cartItems2 = { ...state.items };
      let itemSum;
      let subtotal3;
      let ivaqP3;
      let totalAmountP3;
      if (cartItems2[action.payload.code]) {
        itemSum = state.subtotal - cartItems2[action.payload.code].sum;
      } else {
        itemSum = 0;
      }
      if (state.iva) {
        subtotal3 = itemSum;
        ivaqP3 = subtotal3 * 0.16;
        totalAmountP3 = subtotal3 * 1.16;
      } else {
        subtotal3 = itemSum;
        ivaqP3 = 0;
        totalAmountP3 = subtotal3;
      }
      if (state.quantity === 1) {
        subtotal3 = 0;
        totalAmountP3 = 0;
        ivaqP3 = 0;
      }
      delete cartItems2[action.payload.code];
      return {
        ...state,
        quantity: state.quantity - 1,
        items: cartItems2,
        subtotal: subtotal3,
        ivaQ: ivaqP3,
        totalAmount: totalAmountP3,
      };

    case types.cambio:
      const state1 = { ...state };
      state1.totalAmount = 0;
      state1.subtotal = 0;
      let subtotal2;
      let ivaqP2;
      let totalAmountP2;
      if (!action.payload) {
        for (const key in state1.items) {
          if (Object.hasOwnProperty.call(state1.items, key)) {
            const price = parseFloat(
              state1.items[key].price * state.Pcambio
            ).toFixed(2);
            const quantity = parseInt(state1.items[key].quantity);
            const sum = parseFloat(price * quantity);
            state1.items[key] = {
              ...state1.items[key],
              price: price,
              sum: sum,
            };
            state1.subtotal = state1.subtotal + sum;
            state1.totalAmount = state1.subtotal;
          } else {
            break;
          }
        }
        if (state.iva) {
          subtotal2 = state1.subtotal;
          ivaqP2 = subtotal2 * 0.16;
          totalAmountP2 = subtotal2 * 1.16;
        } else {
          subtotal2 = state1.subtotal;
          ivaqP2 = 0;
          totalAmountP2 = subtotal2;
        }
        return {
          ...state,
          Tmoneda: "MXN",
          cambio: action.payload,
          items: state1.items,
          ivaQ: ivaqP2,
          subtotal: subtotal2,
          totalAmount: totalAmountP2,
        };
      } else {
        for (const key in state1.items) {
          if (Object.hasOwnProperty.call(state1.items, key)) {
            const price = parseFloat(
              state1.items[key].price / state.Pcambio
            ).toFixed(2);
            const quantity = parseInt(state1.items[key].quantity);
            const sum = parseFloat(price * quantity);
            state1.items[key] = {
              ...state1.items[key],
              price: price,
              sum: sum,
            };
            state1.subtotal = state1.subtotal + sum;
            state1.totalAmount = state1.subtotal;
          } else {
            break;
          }
        }
        if (state.iva) {
          subtotal2 = state1.subtotal;
          ivaqP2 = subtotal2 * 0.16;
          totalAmountP2 = subtotal2 * 1.16;
        } else {
          subtotal2 = state1.subtotal;
          ivaqP2 = 0;
          totalAmountP2 = subtotal2;
        }
        return {
          ...state,
          Tmoneda: "USA",
          cambio: action.payload,
          items: state1.items,
          ivaQ: ivaqP2,
          totalAmount: totalAmountP2,
          subtotal: subtotal2,
        };
      }

    case types.iva:
      const state2 = { ...state };
      let amount = 0;
      let iva = 0;
      if (state.quantity !== 0) {
        if (action.payload) {
          amount = parseFloat(state2.totalAmount * 1.16).toFixed(2);
          iva = parseFloat(state2.totalAmount * 0.16).toFixed(2);
          return {
            ...state,
            iva: action.payload,
            subtotal: state2.totalAmount,
            ivaQ: iva,
            totalAmount: amount,
          };
        } else {
          amount = parseFloat(state2.totalAmount - state2.ivaQ).toFixed(2);
          iva = 0;
          return {
            ...state,
            iva: action.payload,
            subtotal: amount,
            ivaQ: iva,
            totalAmount: amount,
          };
        }
      } else {
        amount = 0;
        iva = 0;
        return {
          ...state,
          iva: action.payload,
          subtotal: amount,
          ivaQ: iva,
          totalAmount: amount,
        };
      }

    case types.updateStock:
      let cartItems4 = { ...state.items };
      const code4 = action.payload.code;
      const stock4 = parseInt(action.payload.stock);
      if (cartItems4[code4]) {
        cartItems4[code4].stock = stock4;
      }
      return {
        ...state,
        items: cartItems4,
      };

    case types.emptyCart:
      return {
        ...state,
        items: {},
        quantity: 0,
        totalAmount: 0,
        subtotal: 0,
        ivaQ: 0,
      };
    case types.precios:
      return {
        ...state,
        Pcambio: action.payload.Pcambio,
      };
    default:
      return state;
  }
};

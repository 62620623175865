import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Axios from "axios";
import { API } from "../CONFIG.js";
import { useHistory } from "react-router";
import { Controller, useForm } from "react-hook-form";
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
  Slide,
  TextField,
  Tooltip,
} from "@mui/material";

import "./Register.css";
import { useDispatch } from "react-redux";
import { login } from "../store/actions/auth.js";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Register() {
  const { handleSubmit, control, reset } = useForm({
    mode: "all",
    defaultValues: {
      username: "",
      password: "",
      name: "",
      app: "",
      apm: "",
      email: "",
      phone: "",
      roa: "",
      tc: "",
      rs: "",
      rf: "",
      rfc: "",
      cfdi: "",
      dir: "",
      ce: "",
      empresa: "",
    },
  });
  const dispatch = useDispatch();
  const [pass, setPass] = useState("");
  const [openBackdrop, setOpenBackDrop] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [data, setData] = useState({});
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleAccepted = (data) => {
    setOpenDialog(false);
    setOpenBackDrop(true);
    Axios.post(`${API}/auth/client/register`, {
      username: data.username,
      password: data.password,
      name: data.name,
      app: data.app,
      apm: data.apm,
      email: data.email,
      phone: data.phone,
      roa: data.roa,
      tc: data.tc,
      rs: data.rs,
      rf: data.rf,
      rfc: data.rfc,
      cfdi: data.cfdi,
      dir: data.dir,
      ce: data.ce,
      empresa: data.empresa,
    })
      .then((response) => {
        setData({});
        Axios.post(`${API}/auth/client/login`, {
          username: data.username,
          password: data.password,
        })
          .then((res) => {
            if (!res.data.auth) {
              setOpenBackDrop(false);
              //error
              //setUsuario(response.data.message);
            } else {
              window.localStorage.setItem("TOKENISIA", res.data.token);
              const { id, type, tc, username } = res.data.user;
              setOpenBackDrop(false);
              dispatch(login(id, type, tc, username));
              history.replace("/");
            }
          })
          .catch((e) => {
            //Error en el login
            setOpenBackDrop(false);
          });
      })
      .catch(() => {
        //Error en el registro
        setOpenBackDrop(false);
        alert(
          "Ha ocurrido un error al registrarte por favor intenta de nuevo."
        );
      });
  };
  //Obtener los datos para el select
  const [intc, setIntc] = useState([]);
  const [incfdi, setInCfdi] = useState([]);
  const history = useHistory();
  useEffect(() => {
    document.title = "Registro";
  }, []);
  const getSelects = () => {
    Axios.get(`${API}/auth/client/register/tc`).then((response) => {
      setIntc(response.data);
    });
    Axios.get(`${API}/auth/client/register/cfdi`).then((response) => {
      setInCfdi(response.data);
    });
  };
  const verificarUser = async (user) => {
    return await Axios.get(
      `${API}/auth/client/register/verify/user/${user}`
    ).then((response) => {
      if (response.data.length === 0) {
        return true;
      } else {
        return false;
      }
    });
  };

  const verificarCorreo = async (correo) => {
    return await Axios.get(
      `${API}/auth/client/register/verify/email/${correo}`
    ).then((response) => {
      if (response.data.length === 0) {
        return true;
      } else {
        return false;
      }
    });
  };
  const verificarNumero = async (numero) => {
    return await Axios.get(
      `${API}/auth/client/register/verify/phone/${numero}`
    ).then((response) => {
      if (response.data.length === 0) {
        //true
        return true;
      } else {
        return false;
      }
    });
  };
  const verificarCe = async (ce) => {
    if (ce !== "") {
      return await Axios.get(
        `${API}/auth/client/register/verify/ce/${ce}`
      ).then((response) => {
        if (response.data.length === 0) {
          //true
          return true;
        } else {
          return false;
        }
      });
    } else {
      return true;
    }
  };
  const onChangePass = (e) => {
    setPass(e.target.value);
  };
  const validatePass = (password) => {
    if (pass !== "") {
      if (pass === password) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };
  const onSubmit = (data, e) => {
    e.preventDefault();
    setData(data);
    reset();
    setOpenDialog(true);
  };
  // Para traer la información del la base de datos.
  useEffect(() => {
    getSelects();
  }, []);

  return (
    <Container>
      <Link to="/">
        <img className="register__logo" src="/img/ISiA.png" alt="Logo ISIA" />
      </Link>
      <FormContainer>
        <h1>Crear tu Cuenta</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormRow>
            <FormItems>
              <Controller
                control={control}
                name="username"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    size="small"
                    type="user"
                    label="Usuario"
                    variant="outlined"
                    error={!!error}
                    helperText={
                      error?.type === "text"
                        ? "Usuario requerido"
                        : error?.type === "pattern"
                        ? "Solo números y letras"
                        : error?.type === "valUser"
                        ? "Usuario ya registrado"
                        : ""
                    }
                    inputProps={{ maxLength: 20 }}
                  />
                )}
                rules={{
                  validate: {
                    text: (value) => value !== "",
                    valUser: (value) => verificarUser(value),
                  },
                  pattern: /^[A-Za-z0-9\s]+$/g,
                }}
              />
            </FormItems>
            <FormItems>
              <Controller
                control={control}
                name="password"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <Tooltip
                    title="La contraseña debe contener: mínimo 8 caracteres, mayúsculas, símbolos y números."
                    placement="top"
                  >
                    <TextField
                      {...field}
                      size="small"
                      type="password"
                      label="Contraseña"
                      variant="outlined"
                      onChange={(e) => {
                        field.onChange(e);
                        onChangePass(e);
                      }}
                      error={!!error}
                      helperText={
                        error?.type === "password"
                          ? "Contraseña requerida"
                          : error?.type === "pattern" && "Contraseña no valida"
                      }
                      inputProps={{ maxLength: 20 }}
                    />
                  </Tooltip>
                )}
                rules={{
                  validate: { password: (value) => value !== "" },
                  pattern:
                    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm,
                }}
              />
            </FormItems>
            <FormItems>
              <Controller
                control={control}
                name="newPassword"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    size="small"
                    type="password"
                    label="Repetir Contraseña"
                    variant="outlined"
                    error={!!error}
                    helperText={
                      error?.type === "newPassword"
                        ? "Contraseña requerida"
                        : error?.type === "sameP" &&
                          "Debe de ser la misma contraseña"
                    }
                    inputProps={{ maxLength: 20, id: "newPassword" }}
                  />
                )}
                rules={{
                  validate: {
                    newPassword: (value) => value !== "",
                    sameP: (value) => validatePass(value),
                  },
                }}
              />
            </FormItems>
          </FormRow>

          <FormRow>
            <FormItems>
              <Controller
                control={control}
                name="name"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    size="small"
                    type="name"
                    label="Nombre"
                    variant="outlined"
                    error={!!error}
                    helperText={
                      error?.type === "name"
                        ? "Nombre requerido"
                        : error?.type === "pattern" && "Nombre no valido"
                    }
                    inputProps={{ maxLength: 30 }}
                  />
                )}
                rules={{
                  validate: {
                    name: (value) => value !== "",
                  },
                  pattern:
                    /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u,
                }}
              />
            </FormItems>
            <FormItems>
              <Controller
                control={control}
                name="app"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    size="small"
                    type="text"
                    label="Apellido Paterno"
                    variant="outlined"
                    error={!!error}
                    helperText={
                      error?.type === "app"
                        ? "Apellido Paterno requerido"
                        : error?.type === "pattern" &&
                          "Apellido Paterno no valido"
                    }
                    inputProps={{ maxLength: 20 }}
                  />
                )}
                rules={{
                  validate: {
                    app: (value) => value !== "",
                  },
                  pattern:
                    /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u,
                }}
              />
            </FormItems>
            <FormItems>
              <Controller
                control={control}
                name="apm"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    size="small"
                    type="text"
                    label="Apellido Materno"
                    variant="outlined"
                    error={!!error}
                    helperText={
                      error?.type === "apm"
                        ? "Apellido Materno requerido"
                        : error?.type === "pattern" &&
                          "Apellido Materno no valido"
                    }
                    inputProps={{ maxLength: 20 }}
                  />
                )}
                rules={{
                  validate: {
                    apm: (value) => value !== "",
                  },
                  pattern:
                    /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u,
                }}
              />
            </FormItems>
          </FormRow>

          <FormRow>
            <FormItems>
              <Controller
                control={control}
                name="email"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    size="small"
                    type="email"
                    label="Correo Electrónico"
                    variant="outlined"
                    error={!!error}
                    helperText={
                      error?.type === "email"
                        ? "Correo requerido"
                        : error?.type === "pattern"
                        ? "Correo no valido"
                        : error?.type === "valemail" && "Correo ya registrado"
                    }
                    inputProps={{ maxLength: 30 }}
                  />
                )}
                rules={{
                  validate: {
                    email: (value) => value !== "",
                    valemail: (value) => verificarCorreo(value),
                  },
                  pattern:
                    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g,
                }}
              />
            </FormItems>
            <FormItems>
              <Controller
                control={control}
                name="phone"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    size="small"
                    type="phone"
                    label="Teléfono"
                    variant="outlined"
                    error={!!error}
                    helperText={
                      error?.type === "phone"
                        ? "Teléfono requerido"
                        : error?.type === "pattern"
                        ? "Teléfono no valido"
                        : error?.type === "phonel"
                        ? "Ingrese 10 digitos de tel."
                        : error?.type === "valphone" && "Teléfono ya registrado"
                    }
                    inputProps={{ minLength: 10, maxLength: 10 }}
                  />
                )}
                rules={{
                  validate: {
                    phone: (value) => value !== "",
                    valphone: (value) => verificarNumero(value),
                  },
                  pattern: /^[0-9]/,
                }}
              />
            </FormItems>
            <FormItems></FormItems>
          </FormRow>

          <FormRow>
            <FormItems>
              <Controller
                control={control}
                name="roa"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    size="small"
                    type="text"
                    label="Recomendación o Área"
                    variant="outlined"
                    error={!!error}
                    helperText={error?.type === "pattern" && "RoA no valido"}
                    inputProps={{ maxLength: 50 }}
                  />
                )}
                rules={{
                  pattern:
                    /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u,
                }}
              />
            </FormItems>
            <FormItems>
              <Controller
                control={control}
                name="tc"
                defaultValue=""
                render={({ field }) => (
                  <Select
                    size="small"
                    {...field}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="">
                      <em>Tipo de cliente</em>
                    </MenuItem>
                    {intc.map((value) => (
                      <MenuItem key={value.tc_codigo} value={value.tc_codigo}>
                        {value.tc_descripcion}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormItems>
            <FormItems>
              <Controller
                control={control}
                name="rs"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    size="small"
                    type="text"
                    label="Razón Social"
                    variant="outlined"
                    error={!!error}
                    helperText={
                      error?.type === "pattern" && "Razón Social no valida"
                    }
                    inputProps={{ maxLength: 50 }}
                  />
                )}
                rules={{
                  pattern:
                    /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u,
                }}
              />
            </FormItems>
          </FormRow>

          <FormRow>
            <FormItems>
              <Controller
                control={control}
                name="rf"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    size="small"
                    type="text"
                    label="Regimen Fiscal"
                    variant="outlined"
                    error={!!error}
                    helperText={error?.type === "pattern" && "RF no valido"}
                    inputProps={{ maxLength: 50 }}
                  />
                )}
                rules={{
                  pattern:
                    /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u,
                }}
              />
            </FormItems>
            <FormItems>
              <Controller
                control={control}
                name="rfc"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    size="small"
                    type="text"
                    label="RFC"
                    variant="outlined"
                    error={!!error}
                    helperText={error?.type === "pattern" && "RFC no valido"}
                    inputProps={{ maxLength: 13 }}
                  />
                )}
                rules={{
                  pattern: /^[A-Z-0-9]+$/g,
                }}
              />
            </FormItems>
            <FormItems>
              <Controller
                control={control}
                name="cfdi"
                defaultValue=""
                render={({ field }) => (
                  <Select
                    size="small"
                    {...field}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="">
                      <em>CFDI</em>
                    </MenuItem>
                    {incfdi.map((value) => (
                      <MenuItem
                        key={value.cfdi_codigo}
                        value={value.cfdi_codigo}
                      >
                        {value.cfdi_descripcion}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormItems>
          </FormRow>

          <FormRow>
            <FormItems>
              <Controller
                control={control}
                name="dir"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    size="small"
                    type="text"
                    label="Dirección"
                    variant="outlined"
                    error={!!error}
                    helperText={
                      error?.type === "pattern" && "Dirección no válida"
                    }
                    inputProps={{ maxLength: 50 }}
                  />
                )}
                rules={{
                  pattern: /^[#.0-9a-zA-Z\s,-]+$/,
                }}
              />
            </FormItems>
            <FormItems>
              <Controller
                control={control}
                name="ce"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    size="small"
                    type="email"
                    label="Correo empresarial"
                    variant="outlined"
                    error={!!error}
                    helperText={
                      error?.type === "pattern"
                        ? "CE no valido"
                        : error?.type === "valce" && "CE ya registrado"
                    }
                    inputProps={{ maxLength: 50 }}
                  />
                )}
                rules={{
                  validate: {
                    valce: (value) => verificarCe(value),
                  },
                  pattern:
                    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g,
                }}
              />
            </FormItems>
            <FormItems>
              <Controller
                control={control}
                name="empresa"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    size="small"
                    type="text"
                    label="Empresa"
                    variant="outlined"
                    error={!!error}
                    helperText={
                      error?.type === "pattern" && "Empresa no valida"
                    }
                    inputProps={{ maxLength: 30 }}
                  />
                )}
                rules={{
                  pattern:
                    /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u,
                }}
              />
            </FormItems>
          </FormRow>
          <FormItems>{/** Errores para movil */}</FormItems>

          <Boton>Registrar</Boton>
        </form>

        <ServicesP>
          By signing-in you agree to ISIA`s Conditions of Use & Sale. Please see
          our Privacy Notice, our Cookies Notice and our Interest-Based Ads
          Notice
        </ServicesP>
      </FormContainer>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialog}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"¿Desea aceptar el aviso de privacidad?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Vea nuestro aviso de Privacidad en el siguiente link.
          </DialogContentText>
          <a target="__blank" href="./downloads/AVISO DE PRIVACIDAD_220621.pdf">
            Aviso de privacidad
          </a>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancelar</Button>
          <Button onClick={() => handleAccepted(data)}>Si, Acepto</Button>
        </DialogActions>
      </Dialog>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}
const Container = styled.div`
  background-image: "/img/CARRU_1.jpg";
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const FormContainer = styled.div`
  width: 80%;
  flex-direction: row;
  padding: 25px;
  border: 1px solid lightgray;
  font-weight: 500;
  margin-bottom: 20px;
  h1 {
    font-size: 25px;
    text-align: center;
  }
`;
const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
const FormItems = styled.div`
  width: 100%;
  padding: 15px;
  margin-top: 36px;
`;

const ServicesP = styled.p`
  margin-top: 15px;
  font-size: 12px;
`;

const Boton = styled.button`
  background: var(--main-font-color);
  border-radius: 2px;
  width: 100%;
  padding: 7px 20px;
  border: 1px solid;
  font-size: 14px;
  margin-top: 10px;
  border-color: #a88734;
  cursor: pointer;
  color: white;
  transition: all 0.4s;
  letter-spacing: 1.5px;

  &:hover {
    background-color: lightgray;
    color: var(--main-font-color);
    border: 1px solid var(--main-font-color);
  }
`;

export default Register;

import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";

function PublicRoutes({
  isAuthenticated,
  type,
  component: Component,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={() =>
        !isAuthenticated && type === 0 ? (
          <Component />
        ) : type === 3 ? (
          <Redirect to="/" />
        ) : (
          (type === 1 || type === 2) && <Redirect to="/dashboard" />
        )
      }
    />
  );
}
export default PublicRoutes;
PublicRoutes.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  component: PropTypes.func.isRequired,
  type: PropTypes.number.isRequired,
};

import Axios from 'axios';
import AppRouter from './routers/AppRouter';
import {Provider} from 'react-redux';
import {store} from './store/store';
import { socket, SocketContext } from './socket/socket';
function App() {
  Axios.defaults.withCredentials = true;
  return (
    <Provider store={store}>
      <SocketContext.Provider value={socket}>
        <AppRouter/>
      </SocketContext.Provider>
    </Provider>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Axios from "axios";
import Header from "../Layout/Navigation/Header";
import Footer from "../Layout/Footer/Footer";
import Index from "../Index/Index";
import ChatFacebook from "../chat/ChatFaceook";
import Login from "../auth/Login";
import Offices from "../Offices/Offices";
import Familia from "../Products/Familia";
import Contact from "../Contact/Contact";
import Product from "../Products/Product";
import About from "../Index/About";
import Familias from "../Products/Familias";
import Register from "../auth/Register";
import DashboardRoutes from "./DashboardRoutes";
import LoginUser from "../Dashboard/LoginDashboard/LoginUser";
import PrivateRutes from "./PrivateRutes";
import PublicRoutes from "./PublicRoutes";
import { login, loginUser, logout } from "../store/actions/auth";
import { API } from "../CONFIG";
import "../App.css";
import CartScreen from "../screens/CartScreen";
import { getPrices } from "../store/actions/cart";
import OrdersScreen from "../screens/OrdersScreen";
import ThanksScreen from "../screens/ThanksScreen";
import SplashScreen from "../screens/SplashScreen";
import OrderDetailScreen from "../screens/OrderDetailScreen";
import MarcasScreen from "../screens/MarcasScreen";

function AppRouter() {
  const logged = useSelector((state) => state.auth.logged);
  const type = useSelector((state) => state.auth.user?.type);
  const dispatch = useDispatch();
  const [cheking, setCheking] = useState(true);
  useEffect(() => {
    if (cheking) {
      if (localStorage.getItem("TOKENISIA") !== undefined) {
        Axios.get(`${API}/auth/isauth`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("TOKENISIA")}`,
          },
        })
          .then((res) => {
            const data = res.data.user;
            if (data.hasOwnProperty("suc_clave")) {
              dispatch(getPrices());
              dispatch(
                loginUser(data.id, data.type, data.username, data.suc_clave)
              );
              setCheking(false);
            } else {
              dispatch(getPrices());
              dispatch(login(data.id, data.type, data.tc, data.username));
              setCheking(false);
            }
          })
          .catch((e) => {
            dispatch(getPrices());
            dispatch(logout());
            setCheking(false);
          });
      } else {
        dispatch(getPrices());
        dispatch(logout());
        setCheking(false);
      }
    }
  }, [dispatch, cheking]);

  if (cheking) {
    return <SplashScreen />;
  }

  return (
    <Router>
      <main>
        <Switch>
          {/** PublicRoutes se refiere a las rutas que estan disponibles si los usuarios si no estan logeados
           *  PrivateRoutes se refire a las rutas que estan disponibles solo si esta logeado el usuario.
           */}
          <PublicRoutes
            exact
            path="/users/login"
            component={LoginUser}
            isAuthenticated={logged}
            type={type}
          />
          <PublicRoutes
            exact
            path="/login"
            component={Login}
            isAuthenticated={logged}
            type={type}
          />
          <PublicRoutes
            exact
            path="/register"
            component={Register}
            isAuthenticated={logged}
            type={type}
          />
          <PrivateRutes
            path="/dashboard"
            component={DashboardRoutes}
            isAuthenticated={logged}
            type={type}
          />

          <Route exact path="/">
            <Header />
            <Index />
            <hr />
            <Footer />
            <ChatFacebook />
          </Route>
          <Route exact path="/offices">
            <Header />
            <Offices />
            <hr />
            <Footer />
          </Route>
          <Route exact path="/products/">
            <Header />
            <Familias />
            <hr />
            <Footer />
          </Route>
          <Route exact path="/products/:id">
            <Header />
            <Familia />
            <hr />
            <Footer />
          </Route>
          <Route exact path="/products/:familia/:id">
            <Header />
            <Product />
            <hr />
            <Footer />
          </Route>
          <Route exact path="/contact">
            <Header />
            <Contact />
            <hr />
            <Footer />
          </Route>
          <Route exact path="/about">
            <Header />
            <About />
            <hr />
            <Footer />
          </Route>
          <Route exact path="/cart">
            <Header />
            <CartScreen />
            <hr />
            <Footer />
          </Route>
          <Route exact path="/thanks">
            <Header />
            <ThanksScreen />
            <hr />
            <Footer />
          </Route>
          <Route exact path="/orders">
            <Header />
            <OrdersScreen />
            <hr />
            <Footer />
          </Route>
          <Route exact path="/order/detail">
            <Header />
            <OrderDetailScreen />
            <hr />
            <Footer />
          </Route>
          <Route exact path="/marcas">
            <Header />
            <MarcasScreen />
            <hr />
            <Footer />
          </Route>
          <Route path="*">
            <Header />
            <h1>Página no encontrada 404.</h1>
            <hr />
            <Footer />
          </Route>
        </Switch>
      </main>
    </Router>
  );
}
export default AppRouter;

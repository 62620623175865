import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Axios from "axios";
import styled from "styled-components";
import {
  Alert,
  Backdrop,
  CircularProgress,
  FormHelperText,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import { useSelector } from "react-redux";
import { SocketContext } from "../../../socket/socket";
import { API } from "../../../CONFIG";
import "./Registro.css";

function Registro() {
  const socket = useContext(SocketContext);
  const userAuth = useSelector((state) => state.auth.user);
  const { handleSubmit, reset, control } = useForm({
    mode: "all",
    defaultValues: {
      sucursal: "",
      cod_prod: "",
      destino: "",
      movimiento: 0,
      no_factura: "",
      no_serie: "",
      cantidad: "",
      motivo: "",
    },
  });
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [sucurs, setSucurs] = useState("");
  const [codProducts, setCodProducts] = useState([]);
  const [suc, setSuc] = useState([]);
  const [sucursales, setSucursales] = useState([]);
  const [openError, setOpenError] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const [openSucces, setOpenSuccess] = useState(false);
  const [err, setErr] = useState("");
  const [info, setInfo] = useState("");
  const [success, setSuccess] = useState("");

  const handlerOnChange = (e) => {
    const sucursal = e.target.value;
    if (sucursal !== "") {
      const filter = sucursales.filter((item) => item.suc_clave !== sucursal);
      setSuc(filter);
    }
  };
  const showMesagge = (type, message) => {
    if (type === "error") {
      setErr(message);
      setOpenError(true);
    } else if (type === "info") {
      setInfo(message);
      setOpenInfo(true);
    } else if (type === "success") {
      setSuccess(message);
      setOpenSuccess(true);
    }
  };
  const handleClose = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }
    if (openError) {
      setErr("");
      setOpenError(false);
    }
    if (openInfo) {
      setInfo("");
      setOpenInfo(false);
    }
    if (openSucces) {
      setSuccess("");
      setOpenSuccess(false);
    }
  };

  const validarNum = (valor) => {
    if (valor !== "") {
      const v = parseInt(valor);
      if (v >= 1) {
        return true;
      } else if (v <= 0) {
        return false;
      }
    }
  };

  useEffect(() => {
    Axios.get(`${API}/producto/codigos`).then((response) => {
      setCodProducts(response.data);
    });
    Axios.get(`${API}/sucursal/info`).then((res) => {
      setSucursales(res.data);
      if (userAuth.type === 2) {
        const codigo = userAuth.suc_clave;
        const suc = res.data.filter((item) => item.suc_clave === codigo);
        const sucursal = suc.reduce((item) => item);
        setSucurs(sucursal.suc_nom);
      }
    });
  }, [setCodProducts, setSucursales, userAuth.type, userAuth.suc_clave]);

  const onSubmit = (data, e) => {
    e.preventDefault();
    setOpenBackdrop(true);
    let idSuc;
    if (userAuth.type === 2) {
      idSuc = userAuth.suc_clave;
    } else if (userAuth.type === 1) {
      idSuc = data.sucursal;
    }

    Axios.get(`${API}/almacen/${data.cod_prod}&${idSuc}`).then((response) => {
      if (response.data.length !== 0) {
        const stock = response.data.reduce((item) => item);
        if (
          stock.alm_existencia < data.cantidad &&
          parseInt(data.movimiento) === 2
        ) {
          showMesagge("error", "Producto no disponible en stock");
        } else {
          socket.emit(
            "[client][inv] registro",
            {
              user: userAuth.id,
              sucursal: idSuc,
              destino: data.destino,
              cod_prod: data.cod_prod,
              movimiento: data.movimiento,
              no_factura: data.no_factura,
              no_serie: data.no_serie,
              cantidad: data.cantidad,
              motivo: data.motivo,
            },
            (res) => {
              if (res.insert) {
                setOpenBackdrop(false);
                if (stock.alm_existencia === data.cantidad) {
                  //envia una notificacion si el producto se ha acabado
                  showMesagge(
                    "info",
                    `Ya se ha acabado el produto ${data.cod_prod}`
                  );
                }
                showMesagge(
                  "success",
                  "Ha registrado correctamente en el inventario"
                );
                //e.target.reset();
                reset();
              } else {
                setOpenBackdrop(false);
                reset();
                showMesagge("error", "Se ha producido un error");
              }
            }
          );
        }
      } else {
        if (parseInt(data.movimiento) === 1) {
          socket.emit(
            "[client][inv] registro",
            {
              user: userAuth.id,
              sucursal: idSuc,
              destino: data.destino,
              cod_prod: data.cod_prod,
              movimiento: data.movimiento,
              no_factura: data.no_factura,
              no_serie: data.no_serie,
              cantidad: data.cantidad,
              motivo: data.motivo,
            },
            (res) => {
              if (res.insert) {
                setOpenBackdrop(false);
                showMesagge(
                  "success",
                  "Ha registrado correctamente en el inventario"
                );

                reset();
              } else {
                setOpenBackdrop(false);
                showMesagge("error", "Se ha producido un error");
                reset();
              }
            }
          );
        } else {
          setOpenBackdrop(false);
          showMesagge(
            "error",
            "Producto no registrado en el inventario, Realize una entrada del mismo producto si hay en el almacen."
          );
          reset();
        }
      }
    });
  };

  return (
    <MainContainer>
      <Container>
        <h4>GENERAR REGISTRO DE INVENTARIO</h4>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ContainerRow>
            {userAuth.type === 2 && (
              <SelectContainer>
                <TextField size="small" type="text" value={sucurs} disabled />
              </SelectContainer>
            )}
            {userAuth.type === 1 && (
              <SelectContainer>
                <Controller
                  control={control}
                  name="sucursal"
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <Select
                        {...field}
                        size="small"
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        onChange={(e) => {
                          field.onChange(e);
                          handlerOnChange(e);
                        }}
                        error={!!error}
                      >
                        <MenuItem value="">Seleccione una sucursal</MenuItem>
                        {sucursales.map((value) => (
                          <MenuItem
                            key={value.suc_clave}
                            value={value.suc_clave}
                          >
                            {value.suc_nom}
                          </MenuItem>
                        ))}
                      </Select>
                      {error ? (
                        <FormHelperText error>
                          Sucursal Requerida
                        </FormHelperText>
                      ) : null}
                    </>
                  )}
                  rules={{
                    validate: {
                      suc: (value) => value !== "",
                    },
                  }}
                />
              </SelectContainer>
            )}

            <div>
              <LabelContainer>
                <Controller
                  control={control}
                  name="no_factura"
                  type="text"
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      size="small"
                      label="No. Factura"
                      variant="outlined"
                      error={!!error}
                      helperText={
                        error?.type === "pattern" && "No.Factura no valida"
                      }
                      inputProps={{ maxLength: 200 }}
                    />
                  )}
                  rules={{
                    pattern:
                      /^[a-zA-Z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u,
                  }}
                />
              </LabelContainer>
            </div>
            <div>
              <LabelContainer>
                <Controller
                  control={control}
                  name="no_serie"
                  type="text"
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      size="small"
                      label="No. Serie"
                      variant="outlined"
                      error={!!error}
                      helperText={
                        error?.type === "pattern" && "No.Serie no valida"
                      }
                      inputProps={{ maxLength: 100 }}
                    />
                  )}
                  rules={{
                    pattern:
                      /^[a-zA-Z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u,
                  }}
                />
              </LabelContainer>
            </div>
          </ContainerRow>
          <ContainerRow>
            <SelectContainer>
              <Controller
                control={control}
                name="cod_prod"
                defaultValue=""
                render={({ field, fieldState: { error } }) => (
                  <>
                    <Select
                      {...field}
                      size="small"
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      error={!!error}
                    >
                      <MenuItem value="">Código de producto</MenuItem>
                      {codProducts.map((value) => (
                        <MenuItem
                          key={value.prod_codigo}
                          value={value.prod_codigo}
                        >
                          {value.prod_codigo}
                        </MenuItem>
                      ))}
                    </Select>
                    {error ? (
                      <FormHelperText error>
                        Código de producto requerido
                      </FormHelperText>
                    ) : null}
                  </>
                )}
                rules={{
                  validate: {
                    prod: (value) => value !== "",
                  },
                }}
              />
            </SelectContainer>
            <div>
              <LabelContainer>
                <Controller
                  control={control}
                  name="cantidad"
                  type="number"
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      size="small"
                      label="Cantidad"
                      variant="outlined"
                      error={!!error}
                      helperText={
                        error?.type === "cantidad"
                          ? "Cantidad requerida"
                          : error?.type === "pattern"
                          ? "Cantidad no valida"
                          : error?.type === "cantidadNum" &&
                            "Cantidad debe ser mayor a 0"
                      }
                      inputProps={{ maxLength: 3 }}
                    />
                  )}
                  rules={{
                    validate: {
                      cantidad: (value) => value !== "",
                      cantidadNum: (value) => validarNum(value),
                    },
                    pattern: /^[0-9]/,
                  }}
                />
              </LabelContainer>
            </div>
            <SelectContainer>
              <Controller
                control={control}
                name="destino"
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    size="small"
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="">Origen/Destino</MenuItem>
                    {userAuth.type === 2 &&
                      sucursales.map(
                        (value) =>
                          value.suc_clave !== userAuth.suc_clave && (
                            <MenuItem
                              key={value.suc_clave}
                              value={value.suc_clave}
                            >
                              {value.suc_nom}
                            </MenuItem>
                          )
                      )}
                    {userAuth.type === 1 &&
                      suc.map((value) => (
                        <MenuItem key={value.suc_clave} value={value.suc_clave}>
                          {value.suc_nom}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              />
            </SelectContainer>
          </ContainerRow>
          <ContainerRow>
            <SelectContainer>
              <Controller
                control={control}
                name="movimiento"
                defaultValue={0}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <Select
                      {...field}
                      size="small"
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      error={!!error}
                    >
                      <MenuItem value={0}>Seleccione el movimiento</MenuItem>
                      <MenuItem value={1}>Entrada</MenuItem>
                      <MenuItem value={2}>Salida</MenuItem>
                    </Select>
                    {error ? (
                      <FormHelperText error>
                        Movimineto requerido
                      </FormHelperText>
                    ) : null}
                  </>
                )}
                rules={{
                  validate: {
                    valMov: (value) => validarNum(value),
                  },
                }}
              />
            </SelectContainer>
            <div>
              <LabelContainer>
                <Controller
                  control={control}
                  name="motivo"
                  type="text"
                  defaultValue=""
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      {...field}
                      size="small"
                      label="Motivo"
                      variant="outlined"
                      error={!!error}
                      helperText={
                        error?.type === "pattern" && "Motivo no valido"
                      }
                      inputProps={{ maxLength: 200 }}
                    />
                  )}
                  rules={{
                    pattern: /^[A-Za-z0-9\s]+$/g,
                  }}
                />
              </LabelContainer>
            </div>
          </ContainerRow>

          <div>
            <Snackbar
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              open={openError}
              autoHideDuration={6000}
              onClose={handleClose}
            >
              <Alert
                variant="filled"
                onClose={handleClose}
                severity="error"
                sx={{ width: "100%" }}
              >
                {err}
              </Alert>
            </Snackbar>
            <Snackbar
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              open={openInfo}
              autoHideDuration={6000}
              onClose={handleClose}
            >
              <Alert
                variant="filled"
                onClose={handleClose}
                severity="info"
                sx={{ width: "100%" }}
              >
                {info}
              </Alert>
            </Snackbar>
            <Snackbar
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              open={openSucces}
              autoHideDuration={6000}
              onClose={handleClose}
            >
              <Alert
                variant="filled"
                onClose={handleClose}
                severity="success"
                sx={{ width: "100%" }}
              >
                {success}
              </Alert>
            </Snackbar>
          </div>

          <ContainerRow>
            <Boton>GUARDAR</Boton>
          </ContainerRow>
        </form>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openBackdrop}
        >
          <label>Procesando </label>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </MainContainer>
  );
}

const MainContainer = styled.div`
  display: flex;
  flex: 1;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  margin: 20px;
  padding: 20px;
  justify-content: center;
  align-items: center;
`;
const Container = styled.div`
  margin: 25px;
  h4 {
    font-size: 40px;
    text-align: center;
    padding: 0px;
    margin: 0px;
  }
  span {
    font-size: 18px;
  }
`;
const ContainerRow = styled.div`
  display: flex;
  padding: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
const SelectContainer = styled.div`
  display: flex;
  height: 40px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 30px;
`;
const LabelContainer = styled.label`
  display: flex;
  flex-direction: column;
  margin: 30px;
`;

const Boton = styled.button`
  background: var(--main-font-color);
  border-radius: 2px;
  width: 100%;
  padding: 7px 20px;
  border: 1px solid;
  font-size: 14px;
  margin-top: 10px;
  border-color: #a88734;
  cursor: pointer;
  color: white;
  transition: all 0.4s;
  letter-spacing: 1.5px;

  &:hover {
    background-color: lightgray;
    color: var(--main-font-color);
    border: 1px solid var(--main-font-color);
  }
`;

export default Registro;

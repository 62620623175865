import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Axios from "axios";
import { API } from "../../../CONFIG";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Container, ContainerTable } from "./styles";
const headerAlmacen = [
  {
    id: "sucursal",
    label: "Sucursal",
    align: "left",
    style: {
      fontSize: "18px",
      fontWeight: "bold",
    },
  },
  {
    id: "familia",
    label: "Familia",
    align: "left",
    style: {
      fontSize: "18px",
      fontWeight: "bold",
    },
  },
  {
    id: "codigo_prod",
    label: "Código Producto",
    align: "left",
    style: {
      fontSize: "18px",
      fontWeight: "bold",
    },
  },
  {
    id: "descripcion",
    label: "Descripción",
    align: "center",
    style: {
      fontSize: "18px",
      fontWeight: "bold",
    },
  },
  {
    id: "stock",
    label: "Stock",
    align: "center",
    style: {
      fontSize: "18px",
      fontWeight: "bold",
    },
  },
];
const ProductosAlmacen = ({ sucursal }) => {
  const [tblStock, setTblStock] = useState([]);
  useEffect(() => {
    Axios.get(`${API}/inventario`).then((response) => {
      if (sucursal === "" || sucursal === undefined || sucursal === null) {
        setTblStock(response.data);
      } else {
        const data = response.data.filter((e) => e.sucursal_id === sucursal);
        setTblStock(data);
      }
    });
    return () => {};
  }, [sucursal]);

  return (
    <Container>
      <h4>Productos en almacen</h4>
      <ContainerTable>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {headerAlmacen.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={column.style}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tblStock.map((inv, index) => {
              return (
                <TableRow hover key={index}>
                  <TableCell>{inv.sucursal}</TableCell>
                  <TableCell>{inv.familia}</TableCell>
                  <TableCell>{inv.prod_codigo}</TableCell>
                  <TableCell>{inv.prod_descripcion}</TableCell>
                  <TableCell>{inv.stock}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </ContainerTable>
    </Container>
  );
};
ProductosAlmacen.propTypes = {
  sucursal: PropTypes.string.isRequired,
};
export default ProductosAlmacen;

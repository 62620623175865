import React, { useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  AppBar,
  Badge,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import InventoryIcon from "@mui/icons-material/Inventory";
import { logout } from "../../store/actions/auth";
import "./Navbar.css";
import StoreIcon from "@mui/icons-material/Store";

function Navbar() {
  const [anchorEl, setAnchorEl] = useState(null); //para el menu
  const dispatch = useDispatch();
  const history = useHistory();
  //const user= store.getState().auth.user.type_user;
  const logOut = () => {
    setAnchorEl(null); //para cerrar el menu
    dispatch(logout());
    window.localStorage.removeItem("TOKENISIA");
    history.replace("/");
  };
  const handlerUser = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null); //para cerrar el menu
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <NavLink to="/dashboard">
            <img
              className="header__image__dashboard"
              src={"/img/ISiA_B.png"}
              alt="Logo ISIA"
            />
          </NavLink>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1 }}
          ></Typography>

          <div>
            <IconButton
              size="medium"
              aria-label="Inventario"
              color="inherit"
              onClick={() => {
                history.push("/dashboard/inventario");
              }}
            >
              <InventoryIcon fontSize="medium" />
            </IconButton>
            <IconButton
              size="medium"
              aria-label="Inventario"
              color="inherit"
              onClick={() => {
                history.push("/dashboard/pedidos");
              }}
            >
              <Badge badgeContent={0} color="warning">
                <StoreIcon fontSize="medium" />
              </Badge>
            </IconButton>
            <IconButton
              size="medium"
              aria-label="Usuario Actual"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handlerUser}
              color="inherit"
            >
              <AccountCircleIcon fontSize="medium" />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>Perfil</MenuItem>
              <MenuItem onClick={logOut}>Cerrar sesión</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
      {/* <AppBar>
          <Toolbar>

            
                  
                  
                  <ul className='header__ul__dashboard'>
                    
                    {
                    user===1 && <li><NavLink to="/dashboard/usuarios">Usuarios</NavLink></li>
                    }
                  </ul>
                  <NavLink  to="/dashboard/inventario">Inventario</NavLink>
                  <IconButton
                    size="small"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handlerUser}
                    color="inherit">
                      
                  </IconButton>
                  <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose}>Profile</MenuItem>
                  <MenuItem onClick={handleClose}>My account</MenuItem>
                  <MenuItem onClick={logOut}>Logout</MenuItem>
                </Menu>
          </Toolbar>
      </AppBar> */}
      {/* <div className={styles.offset}></div> */}
    </Box>
  );
}

export default Navbar;

import React, { useEffect, useState } from "react";
import Axios from "axios";
import { API } from "../CONFIG";
import { useLocation } from "react-router-dom";
import {
  Container,
  ContainerTable,
} from "../Dashboard/Componentes/Inventario/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
const headerDetail = [
  {
    id: "prod_code",
    label: "Código de producto",
    align: "left",
    style: {
      fontSize: "18px",
      fontWeight: "bold",
    },
  },
  {
    id: "nom_suc",
    label: "Nombre de sucursal",
    align: "left",
    style: {
      fontSize: "18px",
      fontWeight: "bold",
    },
  },
  {
    id: "precio_unitario",
    label: "Precio unitario",
    align: "left",
    style: {
      fontSize: "18px",
      fontWeight: "bold",
    },
  },
  {
    id: "Cantidad",
    label: "Cantidad",
    align: "left",
    style: {
      fontSize: "18px",
      fontWeight: "bold",
    },
  },
];
const OrderDetailScreen = () => {
  const location = useLocation();
  const [isLoaded, setIsLoaded] = useState(true);
  const [detalle, setDetalle] = useState([]);
  useEffect(() => {
    Axios.get(`${API}/order/view/detail/${location.state}`)
      .then((res) => {
        setDetalle(res.data);
        setIsLoaded(false);
      })
      .catch((e) => {
        setIsLoaded(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (isLoaded) {
    return <div></div>;
  }
  return (
    <Container>
      <h4>Detalle de pedido : {location.state}</h4>
      <ContainerTable>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {headerDetail.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={column.style}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {detalle.map((data, index) => {
              return (
                <TableRow hover key={index}>
                  <TableCell>{data.codigo_producto}</TableCell>
                  <TableCell>{data.nombre_sucursal}</TableCell>
                  <TableCell>{data.precio_unitario}</TableCell>
                  <TableCell>{data.cantidad}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </ContainerTable>
    </Container>
  );
};

export default OrderDetailScreen;

import React, { useEffect } from "react";
import styled from "styled-components";
import Buttons from "./Buttons";
import Viewers from "./Viewers";
import { bannerImages } from "../CONFIG";
import "./Index.css";
import ImgSlider from "./ImgSlider";
import Recommends from "./Recommends";
import Marcas from "./Marcas";

/*
 *  Componente principal del Index.
 */

const Index = () => {
  useEffect(() => {
    document.title = "ISIA México";
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Container>
        <ImgSlider items={bannerImages}></ImgSlider>
        <section className="index__buttons">
          <Buttons className="buttons" />
        </section>
        <Viewers />
        <Recommends title="Lo más vendido" />
        <Marcas />
      </Container>
    </React.Fragment>
  );
};

export default Index;

const Container = styled.div`
  position: relative;
  min-height: calc(100vh - 250px);
  overflow-x: hidden;
  display: block;
  top: 40px;
  padding: 0 calc(3vw);
  margin-bottom: 80px;

  &:after {
    content: "";
    position: absolute;
    inset: 0px;
    opacity: 1;
    z-index: -1;
  }
`;
